


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































 
import Component from 'vue-class-component'
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import EditorComponent from '@/modules/genprox/components/wysiwyg/EditorComponent.vue';
import ProductHistoryTable from '@/modules/genprox/components/wysiwyg/ProductHistoryTable.vue';
import PreviewDoc from '@/modules/accounting/components/PreviewDoc.vue';
import FileUploader from '@/components/FileUploader.vue';
import AttachmentsTable from '@/modules/accounting/components/AttachmentsTable.vue';
import {
  Address,
  DebtorAccount,
  GetProduct,
  Product,
  ProductSummaryAction,
TimeTypes
} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import {mapGetters, mapState} from "vuex";
import {Prop, Watch} from "vue-property-decorator";
import {
  ProductSummaryInvestor,
  ProductSummaryIssuer
} from "@/modules/genprox/modules/fund/modules/capital-rise/models/ProductSummaryData.interface";
import store from "@/store/rootStore";
import {ProductSummaryData} from "@/modules/genprox/modules/fund/modules/capital-rise/models/ProductSummaryData";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import { AgreementObject, AttachmentObject, Template, TemplateAttachment } from '../../modules/templates/store/types';
import { SelectOption } from '@/store/types';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Utils, { UploadProgress } from '@/modules/shared/utils/utils';
import Attachments from '@/modules/genprox/modules/fund/modules/capital-rise/modules/templates/components/Attachments.vue';
import { BACKEND_BASE_URL } from '@/shared/consts';
import AttachmentModal from '@/modules/genprox/modules/fund/modules/capital-rise/modules/templates/components/AttachmentModal.vue';
import { ibanSupportedCountries, usedCurrencies } from "@/shared/consts";
import { integer, maxValue, minValue, required, or, between } from 'vuelidate/lib/validators';
// import { iban } from '@/shared/validators';
import { AddProductSelects, GlobalSelects } from '@/modules/shared/dictionary-store/types';
import { Wallet } from '@/shared/interfaces/Wallet';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import { Contexts } from '@/modules/genprox/models/User';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import SygniInfoBox from '@/components/layout/SygniInfoBox.vue';
import moment from 'moment';
import { Statues } from '@/shared/interfaces/Statues';
import { FileObject } from '@/modules/genprox/store/types';
import CapitalRisePermissions from '../CapitalRisePermissions.vue';
import ScheduleTable from './ScheduleTable.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import SygniTextArea from '@/components/inputs/SygniTextArea.vue';
import { create, all } from 'mathjs'
import _ from 'lodash';
import { greaterThan, iban } from '@/shared/validators';
import SygniLine from '@/components/layout/SygniLine.vue';
import { ClatTaxPayload } from '../../modules/products/store/types';
import ClatTaxModal from '@/components/ClatTaxModal.vue'
import PaymentConfirmations from './PaymentConfirmations.vue';
import { BTooltip } from 'bootstrap-vue'
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';

import * as PoppinsRegular from '@/assets/Poppins-Regular-normal'
import * as PoppinsBold from '@/assets/Poppins-Bold-normal'

const math = create(all);

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniToggleSwitch,
    SygniRadio,
    SygniSelect,
    SygniInfoBox,
    SygniRoundedButton,
    SygniRectButton,
    SygniInput,
    SygniDatePicker,
    SygniContainerTitle, 
    SygniCheckbox,
    EditorComponent,
    Attachments,
    ProductHistoryTable,
    PreviewDoc,
    AttachmentModal,
    SygniLoader,
    SygniFileBox,
    SygniTextArea,
    SygniModal,
    ScheduleTable,
    SygniLine,
    SygniInputError,
    ClatTaxModal,
    PaymentConfirmations,
    BTooltip,
    SygniLinkButton,
    FileUploader,
    AttachmentsTable
  },
  computed:{
    ...mapGetters('dictionary', {
      productSelects: 'addProductsSelects',
      globalSelects: 'globalSelects',
    }),
    ...mapGetters('genprox', {
      activeContextWallets: 'activeContextWallet'
    }),
    ...mapState('products', {
      attachmentsUploadProgress: (state: any) => state.attachmentsUploadProgress,
    })
  }
})
export default class ProductSummary extends CapitalRisePermissions {
  readonly supportedFileFormats: Array<string> = ['pdf', 'jpeg', 'jpg', 'png', 'doc', 'docx'];
  attachmentsUploadProgress!: any;
  usedCurrencies: any = usedCurrencies;
  showRepaymentScheduleErrorModal: boolean = false;
  isSignedAgreementGenerating: boolean = false;
  isAgreementGenerating: boolean = false;
  isLoading: boolean = false;
  productSelects!: AddProductSelects;
  globalSelects!: GlobalSelects;
  activeContextWallets!: Array<Wallet>;
  ProductSummaryAction = ProductSummaryAction;
  templatesList: Template[] = [];
  fixedReplacementDay: boolean = true;
  uploadProgress: UploadProgress = { progress: 0 };
  editMode: boolean = false;
  product: Product | any = {} as Product;
  issuer: ProductSummaryIssuer = null;
  investor: ProductSummaryInvestor = null;
  legalEntityInvestorData: any = null;
  investorDetails: any = null;
  rejectOfferLoading: boolean = false;
  finishProductLoading: boolean = false;
  openedTab: string = 'contract';
  agreementTemplate: any = null;
  showAttachmentPreviewModal: boolean = false;
  selectedAttachment: any = null;
  selectedPaymentConfirmation: any = null;
  initialPaymentBankAccountCountry: string = 'PL';
  principalPaymentBankAccountCountry: string = 'PL';
  interestPaymentBankAccountCountry: string = 'PL';
  selectedAttachmentId: string | null = null;
  paymentData: { paymentValue: number | null, paymentDate: string | null } = { paymentValue: null, paymentDate: null };
  scheduleTableEditMode: boolean = false;
  hasGeneratedSchedule: boolean = false;
  hasSavedSchedule: boolean = false;
  repaymentSchedule: any = {};
  repaymentScheduleInterestTotalGross: number = null
  repaymentScheduleInterestTotalTax: number = null
  repaymentScheduleInterestTotalNet: number = null
  hasClatTaxGenerated: boolean = false;
  isClatTaxLoading: boolean = false;
  baseValue: number = 0;
  showPdfModal: boolean = false;
  isPdfGenerating: boolean = false;
  assignCampaign: boolean = false;
  assignedCampaign: string | null = null;
  campaigns: any[] = [];
  annexedProducts: { show: boolean, id: string, code: string, schedule: any, investmentValue?: number }[] = [];
  seriesOfShares: any[] = []
  clatTaxData: ClatTaxPayload = {
    baseValue: 0,
    currency: 'PLN',
    rate: 1.0000,
    rateDate: null,
    convert: false,
    convertedValue: 0,
    value: 0,
    paymentStatus: 'unpaid',
    description: null,
    paymentDeadline: null,
    paymentDate: null,
  }
  pdfConfiguration: any = {
    client: true,
    code: true,
    agreementDate: true,
    paymentDate: true,
    amount: true,
    interest: true,
    paymentDeadline: true,
    from: true,
    to: true,
    days: true,
    repaymentDate: true,
    gross: true,
    tax: true,
    net: true,
  }
  subscription: any = null
  subscriptionSlots: any = null
  legalEntityData: any = null
  campaignInvestmentReach: number | null = null
  campaignInvestmentValue: number | null = null
  showConfirmationModal: boolean = false
  showPaymentConfirmationModal: boolean = false
  showClatTaxConfirmationModal: boolean = false
  actualProductData: { investmentValue: number, campaignId: string | null } | null = null
  campaign: any = null
  filesLoading: boolean = false
  clatTaxModalOpened: boolean = false
  contextData: any = null
  showDeclineConfirmationModal: boolean = false
  showRejectConfirmationModal: boolean = false
  showCloseConfirmationModal: boolean = false
  paymentsList: any[] = []
  productExchangeInfo: { rateDate: string, rate: number } = {
    rateDate: null,
    rate: null
  }
  paymentConfirmations: any[] = []
  founderOptions: SelectOption[] = [
    {
      label: 'Treat as a founder',
      value: true,
    },
    {
      label: 'Don\'t treat as a founder',
      value: false,
    }
  ]
  showCheckInvestorModal: boolean = false;
  isCheckInvestorModalLoading: boolean = false;
  checkInvestorModalData: { changedFields: string[], propagate: boolean } = {
    changedFields: [],
    propagate: false,
  }
  investorCheckConfirmationModal: { header: string, description: string, type: 'cancel' | 'decline' | 'save', show: boolean } = {
    header: '',
    description: '',
    type: 'cancel',
    show: false,
  }
  autentiCheckModal: { header: string, description: string, type: 'send-product' | 'withdraw-product', show: boolean } = {
    show: false,
    header: 'Are you sure?',
    description: "It looks like there was an error related to Autenti. Before proceeding we'll recommend to verify that error and status directly in Autenti. Do you want to proceed anyway?",
    type: 'send-product',
  }
  hasAutentiActivated: boolean = false;
  isAttachmentsTableBusy: boolean = false;
  refresh: any = null;
  readonly Statues = Statues;

  @Prop({default: ProductSummaryAction.add}) action!: ProductSummaryAction;

  @Prop() productId!: string;

  @Watch('fixedReplacementDay') onFixedReplacementDay() {
    this.removeUnselectedProductData();
  }

  async addFiles(files: Array<File>): Promise<void> {
    this.isAttachmentsTableBusy = true;

    try {
      const payload = { files };
      const requests: Array<any> = await this.$store.dispatch('products/uploadAttachments', { files: payload, productId: this.productId });
      const response = [];
      
      for (const [key, value] of Object.entries(requests)) {
        response.push({ fileName: key, status: value.status, errors: value.status == 'rejected' ? value.data?.response?.data?.violations : [] });
      }

      await this.loadProductData();

      if (response?.find((el: any) => el.status === 'rejected')) {
        this.$notify({
          duration: 3000,
          type: 'error',
          title: 'Error',
          text: 'It looks like not every file was uploaded.'
        })
      }

    } catch (e) {
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: 'It looks like not every file was uploaded.'
      })
    }

    this.isAttachmentsTableBusy = false;
    this.$store.commit('products/setAttachmentsUploadProgress', -1);
  }

  async removeAttachment(id: string) {
    this.isAttachmentsTableBusy = true;

    try {
      await this.$store.dispatch('products/deleteAttachment', { productId: this.productId, attachmentId: id });
      await this.loadProductData();
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isAttachmentsTableBusy = false;
  }

  openCheckInvestorModal() {
    this.showCheckInvestorModal = true;
  }

  setAllChangedFields() {
    const allKeys = Object.keys(this.filteredInvestorCheckData)

    if (this.checkInvestorModalData?.changedFields?.length >= allKeys?.length) {
      this.$set(this.checkInvestorModalData, 'changedFields', [])
    } else {
      this.$set(this.checkInvestorModalData, 'changedFields', allKeys)
    }
  }

  setChangedField(key: string) {
    if (this.checkInvestorModalData?.changedFields?.includes(key)) {
      const foundIndex = this.checkInvestorModalData?.changedFields.indexOf(key);
      if (foundIndex !== -1) {
        this.checkInvestorModalData?.changedFields.splice(foundIndex, 1);
      }
    } else {
      this.checkInvestorModalData?.changedFields?.push(key)
    }
  }

  updateInvestmentValue() {
    if (this.isEquity && this.product?.type === 'LLC_SHARES') {
      if (this.product?.data?.llcShares?.instrumentCount && !this.product?.investmentValue) {
        const objectKey = this.product?.type === 'JOINT_STOCK_SHARES' ? 'seriesOfShares' : 'llcShares'
        this.product.investmentValue = math.number(math.multiply(math.bignumber(this.product?.data[objectKey]?.instrumentCount || 0), math.bignumber(this.product?.data[objectKey]?.issueValue))) || 0
      }
    }
  }

  updateIssueValue() {
    if (this.product?.data?.llcShares?.instrumentCount && this.product?.investmentValue) {
      const issueValue = math.number(math.divide(math.bignumber(this.product?.investmentValue || 0), math.bignumber(this.product?.data?.llcShares?.instrumentCount || 0)))
      this.product.data.llcShares.issueValue = issueValue || 0
    } else {
      this.product.data.llcShares.issueValue = this.subscription?.llcShares?.issueValue
    }
  }

  getValueFormat(value: any, item: any) {
    switch (item?.label) {
      case 'Document Expiry Date':
        if (value?.indefinitely) {
          return 'Indefinitely'
        } else {
          return this.$options.filters?.dateWithoutTime(value?.expiryDate || value)
        }
      case 'Birth Date':
        return this.$options.filters?.dateWithoutTime(value);
      case 'Industry':
        return value?.join(', ')
      case 'Investment Knowledge':
        return value?.toLowerCase();
      case 'Investment Potential':
        if (value === 'UP_TO_10K_PLN') {
          return 'up to 10k PLN'
        } else if (value === 'NOT_INTERESTED') {
          return 'not interested'
        } else if (value === 'UP_TO_100K_PLN') {
          return 'up to 100k PLN'
        } else if (value === 'UP_TO_500K_PLN') {
          return 'up to 500k PLN'
        } else if (value === 'UP_TO_1M_PLN') {
          return 'up to 1m PLN'
        }
        return value;
      case 'Document Type':
        if (value === 'ID_CARD') {
          return 'ID card'
        } else if (value === 'PASSPORT') {
          return 'passport'
        }
        return value;
      default: 
        return value;
    }
  }

  get differentIssueValue() {
    if (this.isEquity || this.product?.type === 'LLC_SHARES') {
      return !!(this.product?.data?.llcShares?.issueValue !== this.subscription?.llcShares?.issueValue)
    }

    return false
  }

  get filteredInvestorCheckData() {
    const filteredInvestorCheckData: any = {}

    for (const [key, value] of Object.entries(this.investorCheckData)) {
      if (key === 'documentExpiryDate') {
        if (value?.oldValue?.expiryDate !== value?.newValue?.expiryDate && value?.oldValue?.indefinitely !== value?.newValue?.indefinitely) {
          filteredInvestorCheckData[key] = value
          filteredInvestorCheckData[key].key = key
        }
      } else {
        if (value?.oldValue !== value?.newValue) {
          filteredInvestorCheckData[key] = value
          filteredInvestorCheckData[key].key = key
        }
      }
    }

    return filteredInvestorCheckData
  }

  get investorCheckData() {
    const oldData = _.cloneDeep(this.investorDetails)
    const newData = _.cloneDeep(this.product?.leadFormData)

    let oldDocumentExpiryDate = oldData?.details?.documentExpiryDate
    let newDocumentExpiryDate = newData?.details?.documentExpiryDate

    if (typeof oldDocumentExpiryDate?.indefinitely === undefined) {
      oldDocumentExpiryDate = {
        expiryDate: oldDocumentExpiryDate,
        indefinitely: !!(oldDocumentExpiryDate)
      }
    }

    if (typeof newDocumentExpiryDate?.indefinitely === undefined) {
      newDocumentExpiryDate = {
        expiryDate: newDocumentExpiryDate,
        indefinitely: !!(newDocumentExpiryDate)
      }
    }

    return {
      // consents
      privacyPolicyAndTermsAndConditions: {
        oldValue: oldData?.consents?.find((el: any) => el?.type === 'privacyPolicyAndTermsAndConditions')?.value || false,
        newValue: newData?.consents?.privacyPolicyAndTermsAndConditions || false,
        order: 34,
        label: 'Privacy Policy And Terms And Conditions',
      },
      consentToTheProcessingOfDataForMarketingPurposes: {
        oldValue: oldData?.consents?.find((el: any) => el?.type === 'consentToTheProcessingOfDataForMarketingPurposes')?.value || false,
        newValue: newData?.consents?.consentToTheProcessingOfDataForMarketingPurposes || false,
        order: 35,
        label: 'Consent To The Processing Of Data For Marketing Purposes',
      },
      consentToTheProcessingOfDataUsingTelecommunicationsTerminalEquipmentAndAutomaticCallingSystems: {
        oldValue: oldData?.consents?.find((el: any) => el?.type === 'consentToTheProcessingOfDataUsingTelecommunicationsTerminalEquipmentAndAutomaticCallingSystems')?.value || false,
        newValue: newData?.consents?.consentToTheProcessingOfDataUsingTelecommunicationsTerminalEquipmentAndAutomaticCallingSystems || false,
        order: 36,
        label: 'Consent To The Processing Of Data Using Telecommunications Terminal Equipment And Automatic Calling Systems',
      },
      // details
      firstName: {
        oldValue: oldData?.generalData?.firstName,
        newValue: newData?.firstName,
        order: 1,
        label: 'First Name',
      },
      surname: {
        oldValue: oldData?.generalData?.surname,
        newValue: newData?.surname,
        order: 2,
        label: 'Surname',
      },
      middleName: {
        oldValue: oldData?.details?.middleName,
        newValue: newData?.details?.middleName,
        order: 3,
        label: 'Middle Name',
      },
      fathersName: {
        oldValue: oldData?.details?.fathersName,
        newValue: newData?.details?.fathersName,
        order: 4,
        label: 'Fathers Name',
      },
      mothersName: {
        oldValue: oldData?.details?.mothersName,
        newValue: newData?.details?.mothersName,
        order: 5,
        label: 'Mothers Name',
      },
      birthDate: {
        oldValue: oldData?.details?.birthDate,
        newValue: newData?.details?.birthDate,
        order: 6,
        label: 'Birth Date',
      },
      birthPlace: {
        oldValue: oldData?.details?.birthPlace,
        newValue: newData?.details?.birthPlace,
        order: 7,
        label: 'Birth Place',
      },
      country: {
        oldValue: oldData?.details?.country,
        newValue: newData?.details?.country,
        order: 8,
        label: 'Country',
      },
      identificationNumber: {
        oldValue: oldData?.details?.identificationNumber,
        newValue: newData?.details?.identificationNumber,
        order: 9,
        label: 'Identification Number',
      },
      nationality: {
        oldValue: oldData?.details?.nationality,
        newValue: newData?.details?.nationality,
        order: 10,
        label: 'Nationality',
      },
      phone: {
        oldValue: oldData?.generalData?.phone,
        newValue: newData?.details?.phone,
        order: 11,
        label: 'Phone Number',
      },
      title: {
        oldValue: oldData?.generalData?.title,
        newValue: newData?.details?.title,
        order: 12,
        label: 'Title',
      },
      // document data
      documentType: {
        oldValue: oldData?.details?.documentType,
        newValue: newData?.details?.documentType,
        order: 13,
        label: 'Document Type',
      },
      documentNumber: {
        oldValue: oldData?.details?.documentNumber,
        newValue: newData?.details?.documentNumber,
        order: 14,
        label: 'Document Number',
      },
      documentExpiryDate: {
        oldValue: oldDocumentExpiryDate,
        newValue: newDocumentExpiryDate,
        order: 15,
        label: 'Document Expiry Date',
      },
      documentCountry: {
        oldValue: oldData?.details?.documentCountry,
        newValue: newData?.details?.documentCountry,
        order: 16,
        label: 'Document Country',
      },
      // additional data
      industry: {
        oldValue: oldData?.generalData?.industry,
        newValue: newData?.industry,
        order: 17,
        label: 'Industry',
      },
      investmentKnowledge: {
        oldValue: oldData?.generalData?.investmentKnowledge,
        newValue: newData?.investmentKnowledge,
        order: 18,
        label: 'Investment Knowledge',
      },
      investmentPotential: {
        oldValue: oldData?.generalData?.investmentPotential,
        newValue: newData?.investmentPotential,
        order: 19,
        label: 'Investment Potential',
      },
      notes: {
        oldValue: oldData?.generalData?.notes,
        newValue: newData?.notes,
        order: 20,
        label: 'Notes',
      },
      // address data
      isMailingAddressSameResidential: {
        oldValue: oldData?.mailingAddressSameResidential,
        newValue: newData?.isMailingAddressSameResidential,
        order: 21,
        label: 'Mailing same as residential',
      },
      'mailingAddress.city': {
        oldValue: oldData?.mailingAddress?.city,
        newValue: newData?.mailingAddress?.city,
        order: 22,
        label: 'Mailing Address City',
      },
      'mailingAddress.country': {
        oldValue: oldData?.mailingAddress?.country,
        newValue: newData?.mailingAddress?.country,
        order: 23,
        label: 'Mailing Address Country',
      },
      'mailingAddress.flatNumber': {
        oldValue: oldData?.mailingAddress?.flatNumber,
        newValue: newData?.mailingAddress?.flatNumber,
        order: 24,
        label: 'Mailing Address Flat Number',
      },
      'mailingAddress.houseNumber': {
        oldValue: oldData?.mailingAddress?.houseNumber,
        newValue: newData?.mailingAddress?.houseNumber,
        order: 25,
        label: 'Mailing Address House Number',
      },
      'mailingAddress.street': {
        oldValue: oldData?.mailingAddress?.street,
        newValue: newData?.mailingAddress?.street,
        order: 26,
        label: 'Mailing Address Street',
      },
      'mailingAddress.zipCode': {
        oldValue: oldData?.mailingAddress?.zipCode,
        newValue: newData?.mailingAddress?.zipCode,
        order: 27,
        label: 'Mailing Address Zip Code',
      },
      'residentialAddress.city': {
        oldValue: oldData?.residentialAddress?.city,
        newValue: newData?.residentialAddress?.city,
        order: 28,
        label: 'Residential Address City',
      },
      'residentialAddress.country': {
        oldValue: oldData?.residentialAddress?.country,
        newValue: newData?.residentialAddress?.country,
        order: 29,
        label: 'Residential Address Country',
      },
      'residentialAddress.flatNumber': {
        oldValue: oldData?.residentialAddress?.flatNumber,
        newValue: newData?.residentialAddress?.flatNumber,
        order: 30,
        label: 'Residential Address Flat Number',
      },
      'residentialAddress.houseNumber': {
        oldValue: oldData?.residentialAddress?.houseNumber,
        newValue: newData?.residentialAddress?.houseNumber,
        order: 31,
        label: 'Residential Address House Number',
      },
      'residentialAddress.street': {
        oldValue: oldData?.residentialAddress?.street,
        newValue: newData?.residentialAddress?.street,
        order: 32,
        label: 'Residential Address Street',
      },
      'residentialAddress.zipCode': {
        oldValue: oldData?.residentialAddress?.zipCode,
        newValue: newData?.residentialAddress?.zipCode,
        order: 33,
        label: 'Residential Address Zip Code',
      },
    }
  }

  get investorCheckPayload() {
    const changedFields = this.checkInvestorModalData?.changedFields

    const payload: any = {
      "isMailingAddressSameResidential": this.investorCheckData?.isMailingAddressSameResidential[changedFields?.includes('isMailingAddressSameResidential') ? 'newValue' : 'oldValue'],
      "residentialAddress": {
        "street": this.investorCheckData['residentialAddress.street'][changedFields?.includes('residentialAddress.street') ? 'newValue' : 'oldValue'],
        "houseNumber": this.investorCheckData['residentialAddress.houseNumber'][changedFields?.includes('residentialAddress.houseNumber') ? 'newValue' : 'oldValue'],
        "flatNumber": this.investorCheckData['residentialAddress.flatNumber'][changedFields?.includes('residentialAddress.flatNumber') ? 'newValue' : 'oldValue'],
        "city": this.investorCheckData['residentialAddress.city'][changedFields?.includes('residentialAddress.city') ? 'newValue' : 'oldValue'],
        "zipCode": this.investorCheckData['residentialAddress.zipCode'][changedFields?.includes('residentialAddress.zipCode') ? 'newValue' : 'oldValue'],
        "country": this.investorCheckData['residentialAddress.country'][changedFields?.includes('residentialAddress.country') ? 'newValue' : 'oldValue']
      },
      "mailingAddress": {
        "street": this.investorCheckData['mailingAddress.street'][changedFields?.includes('mailingAddress.street') ? 'newValue' : 'oldValue'],
        "houseNumber": this.investorCheckData['mailingAddress.houseNumber'][changedFields?.includes('mailingAddress.houseNumber') ? 'newValue' : 'oldValue'],
        "flatNumber": this.investorCheckData['mailingAddress.flatNumber'][changedFields?.includes('mailingAddress.flatNumber') ? 'newValue' : 'oldValue'],
        "city": this.investorCheckData['mailingAddress.city'][changedFields?.includes('mailingAddress.city') ? 'newValue' : 'oldValue'],
        "zipCode": this.investorCheckData['mailingAddress.zipCode'][changedFields?.includes('mailingAddress.zipCode') ? 'newValue' : 'oldValue'],
        "country": this.investorCheckData['mailingAddress.country'][changedFields?.includes('mailingAddress.country') ? 'newValue' : 'oldValue']
      },
      "surname": this.investorCheckData?.surname[changedFields?.includes('surname') ? 'newValue' : 'oldValue'],
      "firstName": this.investorCheckData?.firstName[changedFields?.includes('firstName') ? 'newValue' : 'oldValue'],
      "details": {
        "middleName": this.investorCheckData?.middleName[changedFields?.includes('middleName') ? 'newValue' : 'oldValue'],
        "birthDate": this.investorCheckData?.birthDate[changedFields?.includes('birthDate') ? 'newValue' : 'oldValue'],
        "birthPlace": this.investorCheckData?.birthPlace[changedFields?.includes('birthPlace') ? 'newValue' : 'oldValue'],
        "fathersName": this.investorCheckData?.fathersName[changedFields?.includes('fathersName') ? 'newValue' : 'oldValue'],
        "mothersName": this.investorCheckData?.mothersName[changedFields?.includes('mothersName') ? 'newValue' : 'oldValue'],
        "identificationNumber": this.investorCheckData?.identificationNumber[changedFields?.includes('identificationNumber') ? 'newValue' : 'oldValue'],
        "documentType": this.investorCheckData?.documentType[changedFields?.includes('documentType') ? 'newValue' : 'oldValue'],
        "documentNumber": this.investorCheckData?.documentNumber[changedFields?.includes('documentNumber') ? 'newValue' : 'oldValue'],
        "documentExpiryDate": this.investorCheckData?.documentExpiryDate[changedFields?.includes('documentExpiryDate') ? 'newValue' : 'oldValue'],
        "documentCountry": this.investorCheckData?.documentCountry[changedFields?.includes('documentCountry') ? 'newValue' : 'oldValue'],
        "country": this.investorCheckData?.country[changedFields?.includes('country') ? 'newValue' : 'oldValue'],
        "nationality": this.investorCheckData?.nationality[changedFields?.includes('nationality') ? 'newValue' : 'oldValue'],
        "title": this.investorCheckData?.title[changedFields?.includes('title') ? 'newValue' : 'oldValue'],
        "phone": this.investorCheckData?.phone[changedFields?.includes('phone') ? 'newValue' : 'oldValue']
      },
      "email": this.investorCheckComparison?.oldData?.generalData?.email,
      "industry": this.investorCheckData?.industry[changedFields?.includes('industry') ? 'newValue' : 'oldValue'],
      "investmentPotential": this.investorCheckData?.investmentPotential[changedFields?.includes('investmentPotential') ? 'newValue' : 'oldValue'],
      "investmentKnowledge": this.investorCheckData?.investmentKnowledge[changedFields?.includes('investmentKnowledge') ? 'newValue' : 'oldValue'],
      "notes": this.investorCheckData?.notes[changedFields?.includes('notes') ? 'newValue' : 'oldValue'],
      "status": this.investorCheckComparison?.oldData?.status,
      "consents": {
        "privacyPolicyAndTermsAndConditions": this.investorCheckData?.privacyPolicyAndTermsAndConditions[changedFields?.includes('privacyPolicyAndTermsAndConditions') ? 'newValue' : 'oldValue'],
        "consentToTheProcessingOfDataForMarketingPurposes": this.investorCheckData?.consentToTheProcessingOfDataForMarketingPurposes[changedFields?.includes('consentToTheProcessingOfDataForMarketingPurposes') ? 'newValue' : 'oldValue'],
        "consentToTheProcessingOfDataUsingTelecommunicationsTerminalEquipmentAndAutomaticCallingSystems": this.investorCheckData?.consentToTheProcessingOfDataUsingTelecommunicationsTerminalEquipmentAndAutomaticCallingSystems[changedFields?.includes('consentToTheProcessingOfDataUsingTelecommunicationsTerminalEquipmentAndAutomaticCallingSystems') ? 'newValue' : 'oldValue']
      }
    }

    return payload
  }

  get investorCheckComparison() {
    return {
      oldData: this.investorDetails,
      newData: this.product?.leadFormData,
    }
  }

  closeDeclineConfirmationModals() {
    this.showDeclineConfirmationModal = false
    this.showRejectConfirmationModal = false
    this.showCloseConfirmationModal = false
  }

  async approveCheckInvestor() {
    const action = this.checkInvestorModalData?.propagate ? 'investors/editInvestmentClientAndPropagate' : 'investors/editInvestmentClient';
    this.isCheckInvestorModalLoading = true;
    
    try {
      await this.$store.dispatch(action, { id: this.product?.investmentClientId, data: this.investorCheckPayload })
      await this.$store.dispatch('products/declineUpdateInvestor', this.productId)
      await this.loadProductData()

      this.$notify({
        duration: 3000,
        type: 'success',
        title: 'Success',
        text: 'Investor data saved.'
      })

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    
    this.isCheckInvestorModalLoading = false;
    this.closeCheckInvestorModal()
  }

  async declineCheckInvestor() {
    this.isCheckInvestorModalLoading = true;

    try {
      await this.$store.dispatch('products/declineUpdateInvestor', this.productId)
      await this.loadProductData()

      this.$notify({
        duration: 3000,
        type: 'success',
        title: 'Success',
        text: 'Investor changes declined.'
      })

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isCheckInvestorModalLoading = false;
    this.closeCheckInvestorModal()
  }

  confirmCloseCheckInvestorModal() {
    this.$set(this.investorCheckConfirmationModal, 'header', 'There are unsaved changes')
    this.$set(this.investorCheckConfirmationModal, 'description', 'Do you want to go back without saving these changes?')
    this.$set(this.investorCheckConfirmationModal, 'type', 'cancel')
    this.$set(this.investorCheckConfirmationModal, 'show', true);
  }

  confirmDeclineCheckInvestor() {
    if (!this.checkInvestorModalData?.changedFields?.length) {
      this.$set(this.investorCheckConfirmationModal, 'header', 'Are you sure?')
      this.$set(this.investorCheckConfirmationModal, 'description', 'Do you want to decline all these investor changes?')
      this.$set(this.investorCheckConfirmationModal, 'type', 'decline')
      this.$set(this.investorCheckConfirmationModal, 'show', true);
    }
  }

  confirmApproveCheckInvestor() {
    if (this.checkInvestorModalData?.changedFields?.length) {
      const fieldsList: string[] = this.checkInvestorModalData?.changedFields?.map((el: any) => this.filteredInvestorCheckData[el]?.label || el)
  
      this.$set(this.investorCheckConfirmationModal, 'header', 'Are you sure?')
      this.$set(this.investorCheckConfirmationModal, 'description', `Do you really want to update ${fieldsList?.length > 1 ? 'these' : 'this'} ${fieldsList?.length} ${fieldsList?.length > 1 ? 'fields' : 'field'}? <ul><li>${fieldsList?.join('</li><li>')}</li></ul>`)
      this.$set(this.investorCheckConfirmationModal, 'type', 'save')
      this.$set(this.investorCheckConfirmationModal, 'show', true);
    }
  }

  confirmCheckInvestorModalAction() {
    this.$set(this.investorCheckConfirmationModal, 'show', false)

    if (this.investorCheckConfirmationModal?.type === 'cancel') {
      this.closeCheckInvestorModal()
    } else if (this.investorCheckConfirmationModal?.type === 'decline') {
      this.declineCheckInvestor()
    } else if (this.investorCheckConfirmationModal?.type === 'save') {
      this.approveCheckInvestor()
    }
  }

  closeCheckInvestorModal() {
    this.showCheckInvestorModal = false;
    this.$set(this.checkInvestorModalData, 'changedFields', []);
    this.$set(this.checkInvestorModalData, 'propagate', false);
  }

  get currentAvailableSubscriptionSlots() {
    const currentSubscriptionData: any = this.subscriptionSlots?.find((el: any) => el?.seriesOfSharesName === this.product?.data?.seriesOfShares?.name)

    return currentSubscriptionData?.slots || []
  }

  get interestTypes() {
    const types: any[] = this.productSelects?.interestType?.map((el: any) => {
      if (el?.label === 'Variable') {
        this.$set(el, 'disabled', true)
      }

      return el
    }) || []

    return types
  }

  get isRateDisabled() {
    return this.isRateShown && !(this.product?.status?.toLowerCase() !== 'active')
  }

  get isRateShown() {
    return !(this.product?.currency === 'PLN') || this.isMarketplaceAccountant || !this.productId
  }

  get campaignValidation() {
    if (this.editMode) {
      if (this.campaignInvestmentReach !== null && this.campaignInvestmentValue !== null && this.assignCampaign) {
        if (this.assignedCampaign === this.actualProductData?.campaignId) {
          const campaignInvestmentReach = math.number(math.subtract(math.bignumber(this.campaignInvestmentReach), math.bignumber(this.actualProductData?.investmentValue || 0)))
          const remaining = math.number(math.subtract(math.bignumber(this.campaignInvestmentValue), math.bignumber(campaignInvestmentReach)))
          const difference = math.number(math.subtract(math.bignumber(this.product.investmentValue), math.bignumber(remaining)))
  
          if (this.product.investmentValue > remaining) {
            return {
              $error: true,
              message: `If you save this product, the campaign will exceed the investment amount by <span class="text-nowrap">${this.$options.filters.numberFormat(difference, 2)}${this.product.currency}</span>`,
              modalMessage: `If you save this product, the campaign will exceed the investment amount by <span class="text-nowrap">${this.$options.filters.numberFormat(difference, 2)}${this.product.currency}</span>`
            }
          }
        } else {
          const remaining = math.number(math.subtract(math.bignumber(this.campaignInvestmentValue), math.bignumber(this.campaignInvestmentReach)))
          const difference = math.number(math.subtract(math.bignumber(this.product.investmentValue), math.bignumber(remaining)))
    
          if (this.product.investmentValue > remaining) {
            return {
              $error: true,
              message: `If you assign this product to this campaign, the campaign will exceed the investment amount by <span class="text-nowrap">${this.$options.filters.numberFormat(difference, 2)}${this.product.currency}</span>`,
              modalMessage: `If you save this product, the campaign will exceed the investment amount by <span class="text-nowrap">${this.$options.filters.numberFormat(difference, 2)}${this.product.currency}</span>`
            }
          }
        }
      }
    }

    return {
      $error: false,
      message: ''
    }
  }

  get totalPaymentValue() {
    const aggregatedValueAmount = (this.paymentsList as Array<any>).reduce((accumulator: any, object: any) => {
      return math.add(math.bignumber(accumulator), math.bignumber(object.value?.value))
    }, 0);
    
    return math.number(aggregatedValueAmount) || 0
  }

  get maxPaymentValue() {
    return math.subtract(math.bignumber(this.product?.investmentValue), math.bignumber(this.totalPaymentValue))
  }

  get isTotalPaymentValueReached() {
    return this.totalPaymentValue >= this.product?.investmentValue
  }

  get totalPaymentTooltipMessage() {
    return !this.isTotalPaymentValueReached ? 'The total value of the payment must be equal the value of the investment.' : ''
  }

  get seriesOfSharesOptions() {
    const options = this.seriesOfShares.map((el: any) => {
      return { label: el.name, value: el.name }
    })

    return _.uniqBy(options, 'value') || []
  }

  getSeriesOfSharesLabel(item: any) {
    return `${item.name} (${this.$options.filters.thousandSeparator(item.counterFrom)} - ${this.$options.filters.thousandSeparator(item.counterTo)})`
  }

  createSeriesOfSharesValue(item: any) {
    return `${item.name}_${item?.counterFrom > 0 ? item?.counterFrom : 'X'}-${item?.counterTo > 1 ? item?.counterTo : 'X'}`
  }

  getSeriesOfSharesValue(value: string) {
    const splittedValue = value?.split('_')

    return splittedValue[0] ? splittedValue[0] : null
  }

  get subscriptionPool() {
    const seriesName: string = this.product?.data?.seriesOfShares?.name

    const series: any = this.subscriptionSlots?.find((el: any) => el?.seriesOfSharesName === seriesName)

    const seriesInstrumentCount = series?.subscriptionPool
    const productInstrumentCount = this.product?.data?.seriesOfShares?.instrumentCount

    const finalNumber = math.number(math.subtract(math.bignumber(seriesInstrumentCount || 0), math.bignumber(productInstrumentCount || 0)))

    return finalNumber >= 0 ? finalNumber : 0
  }

  get disableSeriesOfSharesAmounts() {
    return !this.product?.data?.seriesOfShares?.name ? true : false
  }

  get productDataName() {
    return 'data';
  }

  get isEquity() {
    return this.product.type === 'JOINT_STOCK_SHARES' || this.product.type === 'LLC_SHARES'
  }

  get campaignOptions() {
    let campaigns = []

    const campaignItems = this.campaigns.map((el: any) => {
      const obj: any = { label: el.campaignName, value: el.id }

      if (el?.legalEntityName) {
        obj.legalEntityName = el?.legalEntityName
      }

      return obj;
    }) || []

    if (this.product?.campaignId && !campaignItems?.find((el: any) => el.value === this.product?.campaignId)) {
      campaigns.push({ label: this.product?.campaignName, value: this.product?.campaignId })
    }

    campaigns = _.uniq(campaigns.concat(campaignItems))

    return campaigns || [];
  }

  get hasScheduleTab() {
    if(this.editMode || this.isEquity) return false;

    return (this.productId && !this.editMode) && (this.isMarketplaceAdmin || this.isMarketplaceHead || this.isMarketplaceAccountant) ? true : false;
  }

  get hasPaymentTab() {
    if(this.action === ProductSummaryAction.add || this.editMode || !this.isEquity) return false;

    return (this.product?.subscriptionId && !this.editMode) && (this.isMarketplaceAdmin || this.isMarketplaceHead || this.isMarketplaceAccountant) ? true : false
  }

  get hasAnnexTab() {
    if(this.editMode) return false;

    return (this.productId && !this.editMode) && (this.isMarketplaceAdmin || this.isMarketplaceHead) && this.product?.annexSourceReferenceIds?.length ? true : false;
  }

  get hasOtherTab() {
    if(this.action === ProductSummaryAction.add || this.editMode || (this.isEquity && this.product?.currency === 'PLN')) return false

    return (this.product?.clatTax && !this.editMode) && (this.isMarketplaceAdmin || this.isMarketplaceHead || this.isMarketplaceAccountant) ? true : false;
  }

  get totalInvestmentValue() {
    if (this.isEquity) {
      if (this.product?.type === 'JOINT_STOCK_SHARES' && this.editMode) {
        const objectKey = this.product?.type === 'JOINT_STOCK_SHARES' ? 'seriesOfShares' : 'llcShares'
        return math.number(math.multiply(math.bignumber(this.product?.data[objectKey]?.instrumentCount || 0), math.bignumber(this.product?.data[objectKey]?.issueValue))) || 0
      }

      return this.product?.investmentValue
    }
    
    return 0
  }

  get canGenerateSchedulePdf() {
    return (this.pdfConfiguration.client || this.pdfConfiguration.code || this.pdfConfiguration.agreementDate || this.pdfConfiguration.paymentDate || this.pdfConfiguration.amount || this.pdfConfiguration.interest || this.pdfConfiguration.paymentDeadline || this.pdfConfiguration.from || this.pdfConfiguration.to || this.pdfConfiguration.days || this.pdfConfiguration.repaymentDate || this.pdfConfiguration.gross || this.pdfConfiguration.tax || this.pdfConfiguration.net) ? true : false;
  }

  get hasApproveAction() {
    return this.hasSignedAgreementUploaded && this.hasSavedSchedule ? true : false
  }

  get periodCols() {
    let count = 0;

    if(this.pdfConfiguration.from) {
      count++;
    }

    if(this.pdfConfiguration.to) {
      count++;
    }

    if(this.pdfConfiguration.days) {
      count++;
    }

    return count;
  }

  get amountCols() {
    let count = 0;

    if(this.pdfConfiguration.gross) {
      count++;
    }

    if(this.pdfConfiguration.tax) {
      count++;
    }

    if(this.pdfConfiguration.net) {
      count++;
    }

    return count;
  }

  get netTotal() {
    let total = 0;
    this.repaymentSchedule?.items?.forEach((el: any) => {
      if (el.interestAmountNet?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestAmountNet.value)));
      }
    });

    return total ? total : 0;
  }

  get taxTotal() {
    let total = 0;
    this.repaymentSchedule?.items?.forEach((el: any) => {
      if (el.interestTax?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestTax.value)));
      }
    });

    return total ? total : 0;
  }

  get grossTotal() {
    let total = 0;
    this.repaymentSchedule?.items?.forEach((el: any) => {
      if (el.interestAmountGross?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestAmountGross.value)));
      }
    });

    return total ? total : 0;
  }

  get currency() {
    if (this.repaymentSchedule?.items) {
      return this.repaymentSchedule?.items[0]?.interestAmountGross?.currency;
    }

    return '';
  }

  get canGenerateSchedule() {
    if (!(this.product?.interestType === 'FIXED' && this.product?.interestCalculationMethod === '30/365' && this.product?.fixedRepaymentDate && this.product[this.productDataName]?.simpleOptions?.interest !== undefined)) {
      return false;
    }

    return true;
  }

  get paymentDictionaries() {
    return this.$store.getters['products/getDictionary'];
  }

  get generatedAgreements() {
    const data: { agreement: any[], attachments: any[] } = {
      agreement: [],
      attachments: [],
    }

    if(this.product?.agreementData?.agreements && this.product?.agreementData?.agreements?.length > 0) {
      const agreement = this.product.agreementData.agreements.find((el: any) => {
        const useHotfix = new Date("2024-03-18T13:00:00+00:00") >= new Date(this.product?.createdAt)
        if (useHotfix) {
          return el.fileName.split('.').slice(0, -1).join('.') === this.agreementTemplate?.title || el.fileName?.replaceAll('p. ', '').split('.').slice(0, -1).join('.') === this.agreementTemplate?.title;
        } else {
          return el.fileName.split('.').slice(0, -1).join('.') === this.agreementTemplate?.title
        }
      })

      if(agreement) {
        data.agreement = [agreement];

        const agreementIndex = this.product.agreementData.agreements.indexOf(agreement);
        if(agreementIndex >= 0) {
          const attachments = _.cloneDeep(this.product.agreementData.agreements);
          attachments.splice(agreementIndex, 1);

          data.attachments = attachments;
        }
      }

      return data;
    }
    
    return data;
  }

  get hasAgreementGenerated() {
    return this.generatedAgreements?.agreement && this.generatedAgreements?.agreement?.length > 0 ? true : false;
  }

  get hasSignedAgreementUploaded() {
    return this.product?.agreement?.id ? true : false;
  }

  get approveBtnTooltipMessage() {
    if (!this.hasSignedAgreementUploaded) {
      return 'You must upload signed agreement first'
    }

    return !this.hasSavedSchedule ? 'You must approve schedule first' : '';
  }

  get disableInitialPaymentSwiftCode() {
    return this.editable && this.product[this.productDataName]?.initialPaymentBankAccount?.country?.toUpperCase() !== 'PL' ? false : true;
  }

  get disablePrincipalPaymentSwiftCode() {
    return this.editable && this.product[this.productDataName]?.principalPaymentBankAccount?.country?.toUpperCase() !== 'PL' ? false : true;
  }

  get disableInterestPaymentSwiftCode() {
    return this.editable && this.product[this.productDataName]?.interestPaymentBankAccount?.country?.toUpperCase() !== 'PL' ? false : true;
  }

  get editable() {
    if (this.isMarketplaceAccountant) {
      return false;
    }

    return this.product.editable ? this.editMode ? true : false : false; 
  }

  get legalEntityWorkflow() {
    const hardcodedValue = true;
    return (hardcodedValue) ? 1 : 0;
  }

  get enableLegalEntityWorkflowActions() {
    if(this.manageProductsEnabled) {
      return (this.product?.status?.toUpperCase() === Statues.new || this.product?.status?.toUpperCase() === Statues.awaiting || this.product?.status?.toUpperCase() === Statues.pending) ? true : false;
    }

    return false;
  }

  get showAgreementActions() {
    return this.manageProductsEnabled && (this.showWithdrawButton || this.showSendToAutentiButton || this.showGenerateFilesButton)
  }

  get showWithdrawButton() {
    const autentiStatus = this.autentiData?.autentiStatus?.toUpperCase()
    return autentiStatus === 'DRAFT' || autentiStatus === 'PROCESSING'
  }

  get showSendToAutentiButton() {
    const noAgreementGenerated = !this.generatedAgreements?.agreement || this.generatedAgreements?.agreement?.length == 0
    const autentiStatus = this.autentiData?.autentiStatus?.toUpperCase()
    console.log
    return (this.autentiData?.autentiStatus === null || autentiStatus === 'ERROR' || autentiStatus === 'WITHDRAWN' || autentiStatus === 'REJECTED') && this.hasAutentiActivated && !noAgreementGenerated && !(this.product?.status?.toUpperCase() === Statues.active || this.product?.status?.toUpperCase() === Statues.declined)
  }

  get showGenerateFilesButton() {
    const autentiStatus = this.autentiData?.autentiStatus?.toUpperCase()
    return (this.autentiData?.autentiStatus === null || autentiStatus === 'ERROR' || autentiStatus === 'WITHDRAWN' || autentiStatus === 'REJECTED') && !(this.product?.status?.toUpperCase() === Statues.active || this.product?.status?.toUpperCase() === Statues.declined)
  }

  get allowGenerate() {
    if(this.action === ProductSummaryAction.summaryByLegalEntity || this.action === ProductSummaryAction.acceptByIdByLegalEntity) {
      return true;
    }

    return false;
  }

  get activeContextWalletsOptions() {
    const options: any[] = this.activeContextWallets.map(wallet => ({ label: this.$options.filters.bankAccountNumber(wallet.account), value: wallet.account }))
    const selectedBankAccount = this.product?.data?.debtor?.account;

    if (selectedBankAccount && !options?.find((el: any) => el?.value === selectedBankAccount)) {
      options.push({ label: this.$options.filters.bankAccountNumber(selectedBankAccount), value: selectedBankAccount })
    }

    return options
  }

  get ibanSupportedCountries() {
    return ibanSupportedCountries;
  }

  get initialPaymentBankAccountDisplayFormat() {
    return this.ibanSupportedCountries.includes(this.product[this.productDataName]?.initialPaymentBankAccount?.country) ? 'iban' : false;
  }
  get principalPaymentBankAccountDisplayFormat() {
    return this.ibanSupportedCountries.includes(this.product[this.productDataName]?.principalPaymentBankAccount?.country) ? 'iban' : false;
  }
  get interestPaymentBankAccountDisplayFormat() {
    return this.ibanSupportedCountries.includes(this.product[this.productDataName]?.interestPaymentBankAccount?.country) ? 'iban' : false;
  }
  get initialPaymentBankAccountDisplayCheck() {
    return (this.ibanSupportedCountries.includes(this.product[this.productDataName]?.initialPaymentBankAccount?.country)) ? true : false;
  }
  get principalPaymentBankAccountDisplayCheck() {
    return (this.ibanSupportedCountries.includes(this.product[this.productDataName]?.principalPaymentBankAccount?.country)) ? true : false;
  }
  get interestPaymentBankAccountDisplayCheck() {
    return (this.ibanSupportedCountries.includes(this.product[this.productDataName]?.interestPaymentBankAccount?.country)) ? true : false;
  }

  get agreementPath() {
    if(this?.product?.agreement?.path) {
      return `${BACKEND_BASE_URL}${this.product.agreement.path}`;
    }

    return null;
  }

  get hasTemplateId() {
    return this.product?.template?.id || this.product?.agreementTemplateId;
  }

  get isProductSummary() {
    return this.$route.name === 'addNewProductSummary';
  }

  get attachmentsEditMode() {
    if(this.action === ProductSummaryAction.add) {
      return 'template';
    }

    return 'preview';
  }

  get templateAttachments() {
    if(this.product?.template) {
      return this.product?.template?.attachments.concat(this.product?.template?.customAttachments ? this.product.template.customAttachments : []);
    }

    return this.agreementTemplate?.attachments;
  }

  get attachmentOptions(): SelectOption[] {
    return this.templatesList?.map((el: any) => {
      let title: string = el.title
      const obj: any = { label: title, value: el.id }

      if (this.activeUserData?.context?.id !== el?.legalEntityId) {
        obj.legalEntityName = el?.legalEntityName
      }

      return obj
    })
  }

  get redFilledButtonText() {
    if( this.action === ProductSummaryAction.summaryByLegalEntity ) { return 'Go back' }
    else if ( this.action === ProductSummaryAction.summaryByInvestmentClient ) { return 'Go back'}
    else if ( this.action === ProductSummaryAction.add ) { return 'Create product'}
    else if ( this.action === ProductSummaryAction.acceptByIdByLegalEntity ) { return 'Final approval'}
    else if ( this.action === ProductSummaryAction.acceptByIdByInvestmentClient ) { return 'Accept'}
    return 'Accept';
  }

  get redOutlineButtonText() {
    switch (this.action){
      case ProductSummaryAction.acceptByIdByLegalEntity:
        return 'Decline';
      default:
        return 'Reject';
    }
  }

  get disableClatTaxRate() {
    return !this.clatTaxData.convert;
  }

  get interestValue() {
    return this.product[this.productDataName]?.simpleOptions?.interest ? `${this.product[this.productDataName].simpleOptions.interest}%` : `${Number(this.product[this.productDataName]?.advancedOptions?.commission || 0) + Number(this.product[this.productDataName]?.advancedOptions?.margin || 0)}%`;
  }

  get paymentDeadline() {

    if (this.repaymentSchedule?.items?.length > 0) {
      return this.$options.filters.dateWithoutTime(this.repaymentSchedule?.items[this.repaymentSchedule?.items?.length - 1]?.repaymentDate);
    }

    return '';
  }

  get productClatTaxRequirements() {
    return !!(this.product?.code && this.product?.agreementDate && this.product?.type?.toUpperCase() === 'LOAN')
  }

  get canGenerateClatTax() {
    if (!this.productClatTaxRequirements) {
      return false
    }

    if (this.contextData?.taxNumber && this.hasClatTaxGenerated) {
      return true
    }

    return false
  }

  get canSaveClatTax() {
    return this.product?.agreementDate ? true : false
  }

  get clatTaxButtonText() {
    if (!this.hasClatTaxGenerated) {
      return 'CLAT Tax must be saved first'
    }

    if (!this.productClatTaxRequirements) {
      return 'Product needs to have code and agreement date set'
    }

    return (!this.contextData?.taxNumber) ? 'Legal entity needs to have tax number and tax address configured in order to perform this action.' : ''
  }

  closeClatTaxModal() {
    this.clatTaxModalOpened = false
  }

  openClatTaxModal() {
    if (this.canGenerateClatTax) {
      this.clatTaxModalOpened = true
    }
  }

  openClatTaxConfirmationModal() {
    this.showClatTaxConfirmationModal = true
  }

  closeClearClatTaxConfirmationModal() {
    this.showClatTaxConfirmationModal = false
  }

  get productIds() {
    return this.product && this.canGenerateClatTax ? [this.product?.id] : []
  }

  openPdfModal() {
    this.showPdfModal = true;
  }

  closePdfModal() {
    this.showPdfModal = false;
  }

  goToPaymentsView() {
    this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise`, query: { page: 'payments', productCode: this.product.code || '' } });
  }

  closeErrorModal() {
    this.showRepaymentScheduleErrorModal = false;
  }

  disabledPaymentDate(date: Date) {
    date = new Date(moment(new Date(date)).format('YYYY-MM-DD'));
    const dateToCompare = new Date(moment(new Date(this.product?.agreementDate)).format('YYYY-MM-DD'));
    return date < dateToCompare ? true : false;
  }

  statusClass(status: Statues) {
    switch (status?.toUpperCase()) {
      case (Statues.new): {
        return 'black';
      }
      case (Statues.active): {
        return 'primary';
      }
      case (Statues.invited): {
        return 'success';
      }
      case (Statues.awaiting): {
        return 'success';
      }
      case (Statues.pending): {
        return 'black';
      }
      case (Statues.rejected): {
        return 'danger';
      }
      case (Statues.closed): {
        return 'disabled';
      }
      case (Statues.declined): {
        return 'disabled';
      }
    }
    return '';
  }

  statusText(status: Statues): string {
    switch (status?.toUpperCase()) {
      case (Statues.new): {
        return 'New';
      }
      case (Statues.active): {
        return 'Active';
      }
      case (Statues.invited): {
        return 'Awaiting';
      }
      case (Statues.awaiting): {
        return 'Awaiting';
      }
      case (Statues.pending): {
        return 'Pending';
      }
      case (Statues.rejected): {
        return 'Rejected';
      }
      case (Statues.closed): {
        return 'Closed';
      }
      case (Statues.declined): {
        return 'Declined';
      }
    }
    return '';
  }

  async closeScheduleTableEditMode() {
    if(this.hasGeneratedSchedule) {
      await this.generateSchedule(false);
    } else {
      await this.getSchedule();
    }
    this.scheduleTableEditMode = false;
  }

  editInvestor() {

    this.$router.push(`/${this.$route.path.includes('company') ? 'company' : 'fund'}/investor/${this.investor?.id}/edit/form/step-one?productId=${this.productId}`);

    // this.$router.push({ name: 'investors-edit-investor', params: { id: this.investor?.id }, query: { productId: this.productId } });
  }

  async downloadAllGeneratedFiles() {
    this.filesLoading = true
    const tempFiles: any = []
    this.generatedAgreements.agreement.forEach((file: any) => {
      tempFiles.push(file.id);
    })
    this.generatedAgreements.attachments.forEach((file: any) => {
      tempFiles.push(file.id);
    })
    
    const files: { fileName: string, path: string }[] = [];
    
    try {
      for (const fileId of tempFiles) {
        const file = await this.$store.dispatch('genprox/getFileById', fileId)
        files.push({ fileName: this.formatFileName(file.fileName), path: file.filePath })
      }
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    if(this.product?.agreement?.name && this.product?.agreement?.path) {
      files.push({ fileName: this.formatFileName(this.product.agreement.name), path: this.product.agreement.path });
    }

    if(this.product?.agreement?.attachments?.length) {
      this.product.agreement.attachments.forEach((file: any) => {
        files.push({ fileName: this.formatFileName(file.name), path: file.path });
      })
    }

    if(this.product?.agreement?.customAttachments?.length) {
      this.product.agreement.customAttachments.forEach((file: any) => {
        files.push({ fileName: this.formatFileName(file.name), path: file.path });
      })
    }

    let currentDate: Date | string = new Date();
    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    currentDate = `${currentDate.getFullYear()}-${Utils.pad(currentDate.getMonth() + 1, 2)}-${Utils.pad(currentDate.getDate(), 2)}`;
    Utils.downloadZippedFilesByUrls(files, `${this.product?.code ? this.product.code : 'NONE'}-FILES-${currentDate}`);
    this.filesLoading = false
  }

  setDurationType(value: string) {
    if (this.product[this.productDataName].duration === null) {
      this.product[this.productDataName].duration = {
        type: value,
        count: null,
      }
    } else {
      this.product[this.productDataName].duration.type = value;
    }
  }

  setInterestType(value: string) {
    value = 'Fixed'
    this.product[this.productDataName].interestType = value
  }

  setDurationCount(value: number) {
    if (this.product[this.productDataName].duration === null) {
      this.product[this.productDataName].duration = {
        type: null,
        count: value,
      }
    } else {
      this.product[this.productDataName].duration.count = value;
    }
  }

  setFixedRepaymentDate(value: string) {
    this.product[this.productDataName].fixedRepaymentDate = value;
  }

  setBillNumber(value: string) {
    this.product[this.productDataName].billNumber = value;
  }

  setInterestPayment(value: any) {
    this.product[this.productDataName].simpleOptions.interestPayment = value;
  }

  setAdvancedOptions(value: any) {
    this.product[this.productDataName].enabledAdvancedOptions = value;
  }

  setInterestPA(value: any) {
    this.product[this.productDataName].simpleOptions.interest = value ? value : null;
  }

  setCommissionPA(value: any) {
    this.product[this.productDataName].advancedOptions.commission = value ? value : null;
  }

  setCommissionType(value: any) {
    this.product[this.productDataName].advancedOptions.commissionType = value;
  }

  setMarginType(value: any) {
    this.product[this.productDataName].advancedOptions.marginType = value;
  }

  handleInitialPaymentBankAccountSwift(value: any) {
    this.product[this.productDataName].initialPaymentBankAccount.swift = value;
  }

  handleInitialPaymentBankAccountName(value: any) {
    this.product[this.productDataName].initialPaymentBankAccount.name = value;
  }

  handleInitialPaymentBankAccountOwner(value: any) {
    this.product[this.productDataName].initialPaymentBankAccount.owner = value;
  }

  handleInitialPaymentBankAccountCountry(value: any) {
    this.product[this.productDataName].initialPaymentBankAccount.country = value;
  }

  handlePrincipalPaymentBankAccountSwift(value: any) {
    this.product[this.productDataName].principalPaymentBankAccount.swift = value;
  }

  handlePrincipalPaymentBankAccountName(value: any) {
    this.product[this.productDataName].principalPaymentBankAccount.name = value;
  }

  handlePrincipalPaymentBankAccountOwner(value: any) {
    this.product[this.productDataName].principalPaymentBankAccount.owner = value;
  }

  handlePrincipalPaymentBankAccountCountry(value: any) {
    this.product[this.productDataName].principalPaymentBankAccount.country = value;
  }

  handleInterestPaymentBankAccountSwift(value: any) {
    this.product[this.productDataName].interestPaymentBankAccount.swift = value;
  }

  handleInterestPaymentBankAccountName(value: any) {
    this.product[this.productDataName].interestPaymentBankAccount.name = value;
  }

  handleInterestPaymentBankAccountOwner(value: any) {
    this.product[this.productDataName].interestPaymentBankAccount.owner = value;
  }

  handleInterestPaymentBankAccountCountry(value: any) {
    this.product[this.productDataName].interestPaymentBankAccount.country = value;
  }

  setMarginPA(value: any) {
    this.product[this.productDataName].advancedOptions.margin = value ? value : null;
  }

  setCommissionsPayment(value: any) {
    this.product[this.productDataName].advancedOptions.commissionPayment = value;
  }

  setMarginPayment(value: any) {
    this.product[this.productDataName].advancedOptions.marginPayment = value;
  }

  setInterestCalculationMethod(value: any) {
    this.product[this.productDataName].interestCalculationMethod = value;
  }

  setDescription(value: any) {
    this.product.description = value;
  }

  setInterestPaidWithin(value: any) {
    this.product[this.productDataName].interestPaidWithin = value;
  }

  removeUnselectedProductData(): void {
    if (this.fixedReplacementDay) {
      this.product[this.productDataName].duration = null;
    } else {
      delete this.product[this.productDataName]?.fixedRepaymentDate;
    }
    if (this.product[this.productDataName].enabledAdvancedOptions) {
      this.product[this.productDataName].simpleOptions = null;
    } else {
      this.product[this.productDataName].advancedOptions = null;
    }
  }

  getCommissionUnit(type: string) {
    switch(type) {
      case 'percent':
        type = '%';
        break;
      case 'currency':
        type = 'PLN';
        break;
    }

    return type;
  }

  toggleUploadSignedAgreement() {
    (this.$refs.signedAgreementInput as any)[0].click()
  }
  
  toggleUploadSignedAttachment(file: any) {
    this.selectedAttachmentId = file.id;
    (this.$refs.signedAttachmentInput as any)[0].click()
  }

  async uploadSignedAttachment(e: any) {
    this.isSignedAgreementGenerating = true;
    const files = Array.from((e.target as HTMLInputElement).files);
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);
    try {
      const promises: Array<Promise<any>> = [];
      files.forEach(async (file: File) => {
        promises.push(this.$store.dispatch('genprox/uploadFile', { file, config }));
      })

      const uploadedFiles = await Promise.all(promises);
      uploadedFiles.forEach(async (file: FileObject) => {
        const attachment = {
          sourceId: this.selectedAttachmentId,
          fileId: file.id,
          fileName: file.fileName,
        }

        const agreement: any = {
          agreementFileId: this.product?.agreement.id ? this.product.agreement.id : null,
          agreementName: this.product?.agreement.name ? this.product.agreement.name : null,
          attachments: this.product?.agreement?.attachments ? this.product.agreement.attachments.map((el: any) => { return {
            fileId: el.id,
            fileName: el.name,
            sourceId: el.sourceId,
          } }) : [],
        }

        const foundedAttachment = agreement.attachments.find((el: any) => el.sourceId === this.selectedAttachmentId);
        const attachmentIndex = agreement.attachments.indexOf(foundedAttachment);

        if(attachmentIndex >= 0) {
          agreement.attachments[attachmentIndex] = attachment;
        } else {
          agreement.attachments.push(attachment);
        }

        await this.$store.dispatch('investors/saveAgreement', { productId: this.productId, data: agreement });
        await this.loadProductData();

        this.$notify({
          title: 'Success',
          text: 'Agreement has been uploaded',
          type: 'success'
        })

      });
    } catch (e) {
      e;
    }
    e.target.value = '';
    this.isSignedAgreementGenerating = false;
  }

  async deleteSignedAttachment(file: any) {
    this.isSignedAgreementGenerating = true;
    const agreement: any = {
      agreementFileId: this.product?.agreement.id ? this.product.agreement.id : null,
      agreementName: this.product?.agreement.name ? this.product.agreement.name : null,
      attachments: this.product?.agreement?.attachments ? this.product.agreement.attachments.map((el: any) => {
        return {
          fileId: el.id,
          fileName: el.name,
          sourceId: el.sourceId,
        }
      }) : [],
    }

    const attachment = agreement.attachments?.find((el: any) => el.sourceId === file.id);
    const attachmentIndex = agreement.attachments?.indexOf(attachment);

    if(attachmentIndex >= 0) {
      agreement.attachments.splice(attachmentIndex, 1);
    }

    try {
      await this.$store.dispatch('investors/saveAgreement', { productId: this.productId, data: agreement });
      await this.loadProductData();

      this.$notify({
        title: 'Success',
        text: 'Agreement has been deleted',
        type: 'success'
      })

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }

    this.isSignedAgreementGenerating = false;
  }

  async deleteSignedAgreement() {
    this.isSignedAgreementGenerating = true;
    const agreement: any = {
      agreementFileId: null,
      agreementName: null,
      attachments: this.product?.agreement?.attachments ? this.product.agreement.attachments.map((el: any) => {
        return {
          fileId: el.id,
          fileName: el.name,
          sourceId: el.sourceId,
        }
      }) : [],
    }

    try {
      await this.$store.dispatch('investors/saveAgreement', { productId: this.productId, data: agreement });
      await this.loadProductData();

      this.$notify({
        title: 'Success',
        text: 'Agreement has been deleted',
        type: 'success'
      })

    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }

    this.isSignedAgreementGenerating = false;
  }

  async uploadSignedAgreement(e: any) {
    this.isLoading = true;
    this.isSignedAgreementGenerating = true;
    const files = Array.from((e.target as HTMLInputElement).files);
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);
    try {
      const promises: Array<Promise<any>> = [];
      files.forEach(async (file: File) => {
        promises.push(this.$store.dispatch('genprox/uploadFile', { file, config }));
      })

      const uploadedFiles = await Promise.all(promises);
      uploadedFiles.forEach(async (file: FileObject) => {
        const agreement: any = {
          agreementFileId: file.id,
          agreementName: file.fileName,
          attachments: this.product?.agreement?.attachments ? this.product.agreement.attachments.map((el: any) => { return {
            fileId: el.id,
            fileName: el.name,
            sourceId: el.sourceId,
          } }) : [],
        }

        await this.$store.dispatch('investors/saveAgreement', { productId: this.productId, data: agreement });
        // this.openedTab = 'contract';
        await this.loadProductData();
        // await this.loadTemplate();

        this.$notify({
          title: 'Success',
          text: 'Agreement has been uploaded',
          type: 'success'
        })

      });
    } catch (e) {
      e;
    }
    e.target.value = '';
    this.isLoading = false;
    this.isSignedAgreementGenerating = false;
  }

  getInterestTotalGross(schedule: any) {
    let total = 0;
    schedule?.items?.forEach((el: any) => {
      if (el?.paymentType === 'interest' && el.interestAmountGross?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestAmountGross.value)));
      }
    });

    return total ? total : 0;
  }

  getInterestTotalTax(schedule: any) {
    let total = 0;
    schedule?.items?.forEach((el: any) => {
      if (el?.paymentType === 'interest' && el.interestTax?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestTax.value)));
      }
    });

    return total ? total : 0;
  }

  getInterestTotalNet(schedule: any) {
    let total = 0;
    schedule?.items?.forEach((el: any) => {
      if (el?.paymentType === 'interest' && el.interestAmountNet?.value) {
        total = math.number(math.add(math.bignumber(total), math.bignumber(el.interestAmountNet.value)));
      }
    });

    return total ? total : 0;
  }

  sortSchedule(schedule: any) {
    let items = _.cloneDeep(schedule?.items)
    const capitalItems = items.filter((el: any) => el.paymentType === 'capital')
    items = items.filter((el: any) => el.paymentType !== 'capital')
    items = items.concat(capitalItems)
    schedule.items = items

    return schedule
  }

  async generateSchedule(savePaymentInformation: boolean = true) {
    this.isLoading = true;
    try {
      if(savePaymentInformation) {
        await this.savePaymentInformation();
      }
      const schedule = await this.$store.dispatch('products/generateRepaymentSchedule', this.productId);

      this.repaymentSchedule = this.sortSchedule(schedule);
      this.repaymentScheduleInterestTotalGross = this.getInterestTotalGross(this.repaymentSchedule)
      this.repaymentScheduleInterestTotalTax = this.getInterestTotalTax(this.repaymentSchedule)
      this.repaymentScheduleInterestTotalNet = this.getInterestTotalNet(this.repaymentSchedule)
      this.hasGeneratedSchedule = true;
    } catch(e: any) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
    this.isLoading = false;
  }

  async getSchedule(saveSchedule: boolean = false) {
    if (this.productId) {
      this.isLoading = true;
      try {
        const schedule = await this.$store.dispatch('products/getRepaymentSchedule', this.productId);
        this.repaymentSchedule = schedule;
  
        if(this.repaymentSchedule?.items?.length || saveSchedule) {
          this.hasSavedSchedule = true;
        }
      } catch(e: any) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        });
      }
      this.isLoading = false;
    }
  }

  async saveExchangeInfo() {
    if (!this.isRateDisabled) {
      if (!(this.productExchangeInfo?.rate && this.productExchangeInfo?.rateDate)) {
        this.$notify({
          duration: 3000,
          type: 'error',
          title: 'Error',
          text: 'Both exchange rate and exchange date are required.'
        })
  
        return
      }

      this.isLoading = true;
      try {
        await this.$store.dispatch('investors/saveProductRateInfo', { productId: this.productId, data: this.productExchangeInfo })
        this.$notify({
          duration: 3000,
          type: 'success',
          title: 'Success',
          text: 'Exchange rate information saved.'
        })
        
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e)
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }
      this.isLoading = false
    }
  }
  
  async saveRepaymentSchedule() {
    if (!this.isTotalPaymentValueReached) {
      return;
    }

    const data = this.repaymentSchedule.items.map((el: any) => {
      return {
        interestPeriodFrom: el.interestPeriodFrom,
        interestPeriodTo: el.interestPeriodTo,
        interestAmountGross: el.interestAmountGross?.value ? math.number(math.round(math.bignumber(el.interestAmountGross.value), 2)) : 0,
        interestTax: el.interestTax?.value ? math.number(math.round(math.bignumber(el.interestTax.value), 2)) : 0,
        interestAmountNet: el.interestAmountNet?.value ? math.number(math.round(math.bignumber(el.interestAmountNet.value), 2)) : 0,
        interestPeriodDays: 0,
        paymentType: el?.paymentType ? el.paymentType : null,
        repaymentDate: moment().format('YYYY-MM-DD'),
      }
    })

    if(data.filter((el: any) => !el.interestPeriodFrom || !el.interestPeriodTo)?.length) {
      this.showRepaymentScheduleErrorModal = true;
      return;
    }

    this.isLoading = true;
    try {
      await Promise.all([this.loadProductData(), this.$store.dispatch('products/saveRepaymentSchedule', { productId: this.productId, data })])
      await this.getSchedule(true);
      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Schedule saved.'
      })
    } catch(e: any) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });    
    }
    this.scheduleTableEditMode = false;
    this.isLoading = false;
  }

  async savePaymentInformation(showLoading: boolean = false) {
    if (showLoading) {
      this.isLoading = true
    }

    try {
      await this.$store.dispatch('products/savePaymentInformation', {
        productId: this.productId,
        data: {
          paymentValue: this.paymentData.paymentValue,
          paymentDate: this.paymentData.paymentDate,
        }
      })

      if (showLoading) {
        this.$notify({
          duration: 3000,
          type: 'success',
          title: 'Success',
          text: 'Payment details updated.'
        })
      }

    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }

    if (showLoading) {
      this.isLoading = false
    }
  }

  async deletePayment(paymentId: string) {
    this.isLoading = true

    try {
      await this.$store.dispatch('products/deletePayment', { productId: this.productId, paymentId})
      await this.getPaymentsList()
      if (this.hasGeneratedSchedule) {
        if (this.paymentsList?.length) {
          await this.generateSchedule(false)
        } else {
          this.repaymentSchedule = {}
          this.hasGeneratedSchedule = false
        }
      }

      this.$notify({
        duration: 3000,
        type: 'success',
        title: 'Success',
        text: 'Payment deleted.'
      })

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isLoading = false
  }

  async savePayment(showLoading: boolean = false) {
    this.$v?.$touch();
    if (!this.$v?.$error) {
      if (showLoading) {
        this.isLoading = true
      }
  
      try {
        await this.$store.dispatch('products/savePayment', {
          productId: this.productId,
          data: {
            paymentValue: this.paymentData.paymentValue,
            paymentDate: this.paymentData.paymentDate,
          }
        })
  
        this.$v?.$reset()
        this.paymentData.paymentValue = null
        this.paymentData.paymentDate = null
  
        if (showLoading) {
          this.$notify({
            duration: 3000,
            type: 'success',
            title: 'Success',
            text: 'New payment added.'
          })
        }
  
        await this.getPaymentsList()
        if (this.hasGeneratedSchedule) {
          await this.generateSchedule(false)
        }
      } catch(e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        });
      }
  
      if (showLoading) {
        this.isLoading = false
      }
    }
  }

  getDate(date: string | undefined) {
    if(date) return this.$options.filters.dateWithTime(date);

    return '';
  }

  downloadAgreementByUrl(agreement: any) {
    if(agreement?.id) {
      Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${agreement?.path}`, this.formatFileName(agreement?.name));
    }
  }

  async downloadGeneratedFileByUrl(file: any) {
    this.filesLoading = true
    try {
      const fileData = await this.$store.dispatch('genprox/getFileById', file?.id)
      Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${fileData.filePath}`, this.formatFileName(fileData.fileName))
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.filesLoading = false
  }

  hasSignedAttachment(file: any) {
    const attachment = this.product?.agreement?.attachments.find((el: any) => el.sourceId === file.id);

    return attachment ? true : false;
  }

  openESignedAgreementPreview() {
    const attachment = { path: this.autentiData?.autentiFilePath }
    this.openAgreementPreview(attachment)
  }

  downloadESignedAgreementByUrl() {
    if (this.autentiData?.autentiFilePath) {
      const fileName = this.autentiData?.autentiFilePath.split('/').pop().split('?')[0];
      Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${this.autentiData?.autentiFilePath}`, this.autentiData?.autentiFileName ? this.formatFileName(this.autentiData?.autentiFileName) : this.formatFileName(fileName));
    }
  }

  downloadAttachmentByUrl(file: any) {
    const attachment = this.product?.agreement?.attachments.find((el: any) => el.sourceId === file.id);

    if(attachment) {
      Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${attachment.path}`, this.formatFileName(attachment.name));
    }
  }

  openSignedAttachmentPreview(file: any) {
    const attachment = this.product?.agreement?.attachments.find((el: any) => el.sourceId === file.id);

    if (attachment) {
      this.openAttachmentPreview(attachment);
    }
  }

  downloadFileByUrl(file: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.filePath}`, this.formatFileName(file.fileName));
  }

  async closeEditMode() {
    this.$v.$reset();
    this.isLoading = true;
    this.editMode = false;
    await this.loadProductData();
    this.isLoading = false;    
  }

  openConfirmationModal() {
    this.showConfirmationModal = true
  }

  closeConfirmationModal() {
    this.showConfirmationModal = false
  }

  async toggleEditMode(checkValidation: boolean = true) {
    this.$v.$reset();

    if(this.editMode) {
      
      if (this.campaignValidation?.$error && checkValidation) {
        await this.runCampaignTotalsCheck()
        this.openConfirmationModal()
        return
      }

      this.$v.$touch();
      
      if(!this.$v.$error) {
        this.isLoading = true;
        try {
          this.product[this.productDataName].timeType = this.fixedReplacementDay ? TimeTypes.fixedRepaymentDate : TimeTypes.duration;
          await this.$store.dispatch('investors/editProduct', { productId: this.productId, data: this.product });

          if(this.assignedCampaign && !this.product?.campaignProductOfferId && this.assignedCampaign !== this.product?.campaignId) {
            await this.$store.dispatch('campaigns/assignProductToCampaign', { productId: this.productId, campaignId: this.assignedCampaign });
          }
          
          if((this.product?.campaignId && !this.assignCampaign) || (this.product?.campaignId && !this.assignedCampaign)) {
            await this.$store.dispatch('campaigns/assignProductToCampaign', { productId: this.productId, campaignId: null });
          }

          this.closeConfirmationModal()
          await this.loadProductData();
          await this.loadTemplate();

          if (!checkValidation) {
            this.checkCampaignTotals()
            // this.campaignInvestmentReach = null
            // this.campaignInvestmentValue = null
          }

          this.$notify({
            type: 'success',
            title: 'Success',
            text: 'Product updated'
          })
          this.isLoading = false;
        } catch(e) {
          const errorMessage = this.$options.filters.errorHandler(e);
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: this.$t(errorMessage).toString()
          });
          this.isLoading = false;
          return;
        }
      } else {
        this.$nextTick(() => {
          const appContentDiv: HTMLDivElement = document.querySelector('.app-content');
          if (appContentDiv.querySelectorAll('.error')[0]) {
            (appContentDiv.querySelectorAll('.error')[0] as HTMLDivElement).scrollIntoView({
              behavior: 'smooth'
            });
          }
        })
        return;
      }
    }

    this.editMode = !this.editMode;
  }

  async markAsClosed() {
    this.isLoading = true;
    try {
      await this.$store.dispatch('products/closeProduct', this.productId)
      await this.loadProductData()
      this.closeDeclineConfirmationModals()
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Product closed successfully',
      })
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.isLoading = false;
  }

  selectedBankAccountName(accountNumber: DebtorAccount): string {
    return this.activeContextWallets.find(wallet => wallet.account === accountNumber?.account)?.name;
  }
  selectedBankAccountCurrency(accountNumber: DebtorAccount): string {
    return this.activeContextWallets.find(wallet => wallet.account === accountNumber?.account)?.currency;
  }

  handleInitialPaymentBankAccountKeyEvent(e: string): void {
    this.product[this.productDataName].initialPaymentBankAccount.account = e.toLocaleUpperCase();
  }
  handlePrincipalPaymentBankAccountKeyEvent(e: string): void {
    this.product[this.productDataName].principalPaymentBankAccount.account = e.toLocaleUpperCase();
  }
  handleInterestPaymentBankAccountKeyEvent(e: string): void {
    this.product[this.productDataName].interestPaymentBankAccount.account = e.toLocaleUpperCase();
  }

  overwriteInitialPaymentBankAccount(e: any) {
    if (e) this.product[this.productDataName].initialPaymentBankAccount.account = e?.target.value;
    if (this.product[this.productDataName].initialPaymentBankAccount.account && this.ibanSupportedCountries.includes(this.product[this.productDataName].initialPaymentBankAccount.country)) {
      const containsCode = (/^[A-Za-z]+$/).test(this.product[this.productDataName].initialPaymentBankAccount.account.substring(0, 1)) || (/^[A-Za-z]+$/).test(this.product[this.productDataName].initialPaymentBankAccount.account.substring(1, 2));
      if (!containsCode && this.product[this.productDataName].initialPaymentBankAccount.country) {
        this.product[this.productDataName].initialPaymentBankAccount.account = `${this.product[this.productDataName].initialPaymentBankAccount.country}${this.product[this.productDataName].initialPaymentBankAccount.account}`;
      }
      if (containsCode && this.product[this.productDataName].initialPaymentBankAccount.country) {
        this.product[this.productDataName].initialPaymentBankAccount.account = `${this.product[this.productDataName].initialPaymentBankAccount.country}${this.product[this.productDataName].initialPaymentBankAccount.account.slice(2, this.product[this.productDataName].initialPaymentBankAccount.account.length)}`
      }
    }
  }

  overwritePrincipalPaymentBankAccount(e: any) {
    if (e) this.product[this.productDataName].principalPaymentBankAccount.account = e?.target.value;
    if (this.product[this.productDataName].principalPaymentBankAccount.account && this.ibanSupportedCountries.includes(this.product[this.productDataName].principalPaymentBankAccount.country)) {
      const containsCode = (/^[A-Za-z]+$/).test(this.product[this.productDataName].principalPaymentBankAccount.account.substring(0, 1)) || (/^[A-Za-z]+$/).test(this.product[this.productDataName].principalPaymentBankAccount.account.substring(1, 2));
      if (!containsCode && this.product[this.productDataName].principalPaymentBankAccount.country) {
        this.product[this.productDataName].principalPaymentBankAccount.account = `${this.product[this.productDataName].principalPaymentBankAccount.country}${this.product[this.productDataName].principalPaymentBankAccount.account}`;
      }
      if (containsCode && this.product[this.productDataName].principalPaymentBankAccount.country) {
        this.product[this.productDataName].principalPaymentBankAccount.account = `${this.product[this.productDataName].principalPaymentBankAccount.country}${this.product[this.productDataName].principalPaymentBankAccount.account.slice(2, this.product[this.productDataName].principalPaymentBankAccount.account.length)}`
      }
    }
  }

  overwriteInterestPaymentBankAccount(e: any) {
    if (e) this.product[this.productDataName].interestPaymentBankAccount.account = e?.target.value;
    if (this.product[this.productDataName].interestPaymentBankAccount.account && this.ibanSupportedCountries.includes(this.product[this.productDataName].interestPaymentBankAccount.country)) {
      const containsCode = (/^[A-Za-z]+$/).test(this.product[this.productDataName].interestPaymentBankAccount.account.substring(0, 1)) || (/^[A-Za-z]+$/).test(this.product[this.productDataName].interestPaymentBankAccount.account.substring(1, 2));
      if (!containsCode && this.product[this.productDataName].interestPaymentBankAccount.country) {
        this.product[this.productDataName].interestPaymentBankAccount.account = `${this.product[this.productDataName].interestPaymentBankAccount.country}${this.product[this.productDataName].interestPaymentBankAccount.account}`;
      }
      if (containsCode && this.product[this.productDataName].interestPaymentBankAccount.country) {
        this.product[this.productDataName].interestPaymentBankAccount.account = `${this.product[this.productDataName].interestPaymentBankAccount.country}${this.product[this.productDataName].interestPaymentBankAccount.account.slice(2, this.product[this.productDataName].interestPaymentBankAccount.account.length)}`
      }
    }
  }

  openAgreementTab() {
    if (this.hasTemplateId && !this.editMode) {
      this.openedTab = 'agreement';
    }
  }

  openAnnexTab() {
    if (this.hasAnnexTab) {
      this.openedTab = 'annex';
    }
  }

  openScheduleTab() {
    if (this.hasScheduleTab) {
      this.openedTab = 'schedule';
      this.getPaymentsList()
    }
  }

  openPaymentTab() {
    if (this.hasPaymentTab) {
      this.openedTab = 'payment';
      this.getPaymentsList()
    }
  }

  openOtherTab() {
    if(this.hasOtherTab) {
      this.openedTab = 'other';
    }
  }

  closeAttachmentPreviewModal() {
    this.showAttachmentPreviewModal = false;
  }

  openAttachmentPreview(attachment: any) {
    this.selectedAttachment = attachment;
    this.showAttachmentPreviewModal = true;
  }

  openPaymentConfirmationPreview(paymentConfirmation: any) {
    this.selectedPaymentConfirmation = paymentConfirmation
    this.showPaymentConfirmationModal = true
  }

  closePaymentConfirmationPreview() {
    this.showPaymentConfirmationModal = false;
  }

  openAgreementPreview(agreement: any) {
    this.selectedAttachment = agreement;
    this.showAttachmentPreviewModal = true;
  }

  async openAsyncAttachmentPreview(attachment: any) {
    this.isLoading = true
    try {
      const file = await this.$store.dispatch('genprox/getFileById', attachment.id)
      this.openAttachmentPreview(file)
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.isLoading = false
  }

  async openAsyncAgreementPreview(agreement: any) {
    this.isLoading = true
    try {
      const file = await this.$store.dispatch('genprox/getFileById', agreement.id)
      this.openAgreementPreview(file)
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.isLoading = false
  }

  setDebtor(value: string) {
    const option: any = this.activeContextWallets.find((el: any) => el.account === value);

    const debtor = {
      account: option.account,
      name: option.name,
      currency: option.currency,
      type: option.type,
    }
    this.product[this.productDataName].debtor = debtor;
  }

  setDebtorName(value: string) {
    this.product[this.productDataName].debtor.name = value;
  }

  async loadTemplate() {
    const templateId = this.product?.template?.id ? this.product.template.id : this.product?.agreementTemplateId;
    if (templateId) {
      const action = this.action === ProductSummaryAction.acceptByIdByInvestmentClient || this.action === ProductSummaryAction.summaryByInvestmentClient ? 'templates/getAgreementTemplate' : 'templates/getTemplate';
      const response = await this.$store.dispatch(action, templateId);
      this.agreementTemplate = response;
      if (this.hasAutenti) {
        this.hasAutentiActivated = response?.autentiActive || false
      }
    } else {
      this.agreementTemplate = null;
    }
  }
  
  async generateAgreementFile(attachments: AttachmentObject[] | any[], returnFiles: boolean = false) {
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);
    const file = await this.generatePdf(this.agreementTemplate.content, this.agreementTemplate.title);

    const agreement = await this.$store.dispatch('genprox/uploadFile', { file, config })

    if(returnFiles) {
      const response: { agreement: File, attachments: any[] } = {
        agreement,
        attachments
      }

      return response;
    }

    const response: AgreementObject = {
      agreementFileId: agreement.id,
      agreementName: this.agreementTemplate.title,
      attachments: attachments,
    }

    return response;
  }

  getFormattedContent(html: string) {
    const div = document.createElement('div');
    const body = html;
    div.innerHTML = body;

    div.innerHTML = (this.$refs.editorComponentEl as EditorComponent).applyValues(div);
    
    return div.innerHTML;
  }

  wrapUncontainedTextWithSpan(nodes: NodeList[] | Set<HTMLElement>) {
    function wrapNode(node: any, wrapper: any) {
      node.parentNode.insertBefore(wrapper, node);
      wrapper.appendChild(node);
    }
    function wrapTextContent(node: any) {
      if (node.nodeType !== Node.TEXT_NODE) {
        return;
      }
      if (node.textContent.trim() === 0) {
        return;
      }
      const span = document.createElement("span");
      wrapNode(node, span);
    }

    nodes.forEach(function wrapEachTextContent(node: any) {
      node.childNodes.forEach(wrapTextContent);
    });
  }

  async confirmPdfGeneration() {
    if(this.canGenerateSchedulePdf) {
      this.isPdfGenerating = true;
      try {
        await this.generatePdf2(this.$refs?.pdfContent, this.product?.code);
      } catch(error) {
        const errorMessage = this.$options.filters.errorHandler(error);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        });
      }
      this.isPdfGenerating = false;
    }
  }

  async generatePdf2(orgContent: any, fileName: string) {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4',
      putOnlyUsedFonts: true,
      floatPrecision: 16,
    }).setDisplayMode('fullwidth');

    PoppinsRegular;
    PoppinsBold;

    doc.setFont('Poppins');

    (doc as any).autoTable({ html: (orgContent as HTMLElement)?.querySelector('.pdf-content__details'), theme: 'grid', styles: { font: 'Poppins', lineColor: '38303b', lineWidth: 0, cellPadding: { top: 3, bottom: 3 } }, columnStyles: { 1: { halign: 'right', fontStyle: 'bold' } } });
    (doc as any).autoTable({ 
      html: (orgContent as HTMLElement)?.querySelector('.pdf-content__table'), 
      theme: 'grid', 
      styles: { font: 'Poppins', lineColor: '38303b', lineWidth: 1 }, 
      headStyles: { halign: 'center', fillColor: 'FFFFFF', textColor: '38303b' }, 
      footStyles: { halign: 'left', fillColor: 'FFFFFF', textColor: '38303b' },
      didParseCell: (data: any) => {
        if(data.section === 'head') {
          if(data.row.index === 1) {
            data.cell.styles.halign = 'left'
          }
        }
      }
    });

    const pageCount = (doc.internal as any).getNumberOfPages();

    // For each page, print the page number and the total pages
    for (var i = 1; i <= pageCount; i++) {
      // Go to page i
      doc.setPage(i);
      //Print Page 1 of 4 for example
      doc.setFontSize(10);
      const dateString = moment().format('YYYY-MM-DD HH:mm:ss');
      doc.text(`Wygenerowano ${dateString}`, 30, ((doc as any).getPageHeight()) - 30, {
        align: 'left',
        flags: {
          noBOM: false,
          autoencode: true
        }
      })
      doc.text(String(i) + ' / ' + String(pageCount), ((doc as any).getPageWidth()) - 30, ((doc as any).getPageHeight()) - 30, {
        align: 'right',
        flags: {
          noBOM: false,
          autoencode: true
        }
      });
    }

    doc.save(fileName);
  }

  unwrap (wrapper: any) {
    wrapper.replaceWith(...wrapper.childNodes)
  }

  async generatePdf(orgContent: any, fileName: string) {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4',
      putOnlyUsedFonts: true,
      floatPrecision: 16,
    });
    doc.setFontSize(4);
    doc.setFont('Poppins');

    orgContent = this.getFormattedContent(orgContent);

    const content: any = document.createElement('div');
    content.classList.add('wysiwyg-editor-pdf');
    orgContent.replace(/[\u0080-\uffff]/g, '');
    content.innerHTML = orgContent;

    const style = document.createElement('style');
    style.innerHTML = `
      /* http://meyerweb.com/eric/tools/css/reset/ 
        v2.0 | 20110126
        License: none (public domain)
      */

      .wysiwyg-editor-pdf html, .wysiwyg-editor-pdf body, .wysiwyg-editor-pdf div, .wysiwyg-editor-pdf span, .wysiwyg-editor-pdf applet, .wysiwyg-editor-pdf object, .wysiwyg-editor-pdf iframe,
      .wysiwyg-editor-pdf h1, .wysiwyg-editor-pdf h2, .wysiwyg-editor-pdf h3, .wysiwyg-editor-pdf h4, .wysiwyg-editor-pdf h5, .wysiwyg-editor-pdf h6, .wysiwyg-editor-pdf p, .wysiwyg-editor-pdf blockquote, .wysiwyg-editor-pdf pre,
      .wysiwyg-editor-pdf a, .wysiwyg-editor-pdf abbr, .wysiwyg-editor-pdf acronym, .wysiwyg-editor-pdf address, .wysiwyg-editor-pdf big, .wysiwyg-editor-pdf cite, .wysiwyg-editor-pdf code,
      .wysiwyg-editor-pdf del, .wysiwyg-editor-pdf dfn, .wysiwyg-editor-pdf em, .wysiwyg-editor-pdf img, .wysiwyg-editor-pdf ins, .wysiwyg-editor-pdf kbd, .wysiwyg-editor-pdf q, .wysiwyg-editor-pdf s, .wysiwyg-editor-pdf samp,
      .wysiwyg-editor-pdf small, .wysiwyg-editor-pdf strike, .wysiwyg-editor-pdf strong, .wysiwyg-editor-pdf sub, .wysiwyg-editor-pdf sup, .wysiwyg-editor-pdf tt, .wysiwyg-editor-pdf var,
      .wysiwyg-editor-pdf b, .wysiwyg-editor-pdf u, .wysiwyg-editor-pdf i, .wysiwyg-editor-pdf center,
      .wysiwyg-editor-pdf dl, .wysiwyg-editor-pdf dt, .wysiwyg-editor-pdf dd, .wysiwyg-editor-pdf ol, .wysiwyg-editor-pdf ul, .wysiwyg-editor-pdf li,
      .wysiwyg-editor-pdf fieldset, .wysiwyg-editor-pdf form, .wysiwyg-editor-pdf label, .wysiwyg-editor-pdf legend,
      .wysiwyg-editor-pdf table, .wysiwyg-editor-pdf caption, .wysiwyg-editor-pdf tbody, .wysiwyg-editor-pdf tfoot, .wysiwyg-editor-pdf thead, .wysiwyg-editor-pdf tr, .wysiwyg-editor-pdf th, .wysiwyg-editor-pdf td,
      .wysiwyg-editor-pdf article, .wysiwyg-editor-pdf aside, .wysiwyg-editor-pdf canvas, .wysiwyg-editor-pdf details, .wysiwyg-editor-pdf embed, 
      .wysiwyg-editor-pdf figure, .wysiwyg-editor-pdf figcaption, .wysiwyg-editor-pdf footer, .wysiwyg-editor-pdf header, .wysiwyg-editor-pdf hgroup, 
      .wysiwyg-editor-pdf menu, .wysiwyg-editor-pdf nav, .wysiwyg-editor-pdf output, .wysiwyg-editor-pdf ruby, .wysiwyg-editor-pdf section, .wysiwyg-editor-pdf summary,
      .wysiwyg-editor-pdf time, .wysiwyg-editor-pdf mark, .wysiwyg-editor-pdf audio, .wysiwyg-editor-pdf video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }
      /* HTML5 display-role reset for older browsers */
      .wysiwyg-editor-pdf article, .wysiwyg-editor-pdf aside, .wysiwyg-editor-pdf details, .wysiwyg-editor-pdf figcaption, .wysiwyg-editor-pdf figure, 
      .wysiwyg-editor-pdf footer, .wysiwyg-editor-pdf header, .wysiwyg-editor-pdf hgroup, .wysiwyg-editor-pdf menu, .wysiwyg-editor-pdf nav, .wysiwyg-editor-pdf section {
        display: block;
      }
      .wysiwyg-editor-pdf body {
        line-height: 1;
      }
      .wysiwyg-editor-pdf ol, .wysiwyg-editor-pdf ul {
        list-style: none;
      }
      .wysiwyg-editor-pdf blockquote, .wysiwyg-editor-pdf q {
        quotes: none;
      }
      .wysiwyg-editor-pdf blockquote:before, .wysiwyg-editor-pdf blockquote:after,
      .wysiwyg-editor-pdf q:before, .wysiwyg-editor-pdf q:after {
        content: '';
        content: none;
      }
      .wysiwyg-editor-pdf table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      // end of reset.css

      .wysiwyg-editor-pdf h1, .wysiwyg-editor-pdf h2, .wysiwyg-editor-pdf h3, .wysiwyg-editor-pdf h4, .wysiwyg-editor-pdf h5, .wysiwyg-editor-pdf h6, .wysiwyg-editor-pdf p, .wysiwyg-editor-pdf ul li, .wysiwyg-editor-pdf ol li, .wysiwyg-editor-pdf strong, .wysiwyg-editor-pdf b, .wysiwyg-editor-pdf em, .wysiwyg-editor-pdf i {
        box-sizing: border-box !important;
        line-height: 1.5 !important;
        padding: 0 !important;
        margin: 0 !important;
        font-weight: 400;
      }

      .wysiwyg-editor-pdf * {
        vertical-align: middle !important;
        line-height: 1.5 !important;
      }

      .wysiwyg-editor-pdf div[data-type="custom-title-node"] {
        border-bottom: 1px solid black !important;
        border-top: 1px solid black !important;
        text-align: center !important;
        min-height: 33px !important;
        font-weight: 700 !important;
        font-size: 13px !important;
        padding: 10px 0 !important;
      }

      .wysiwyg-editor-pdf h1, .wysiwyg-editor-pdf h2 {
        font-weight: 700 !important;
        font-size: 13px !important;
      }

      .wysiwyg-editor-pdf h3, .wysiwyg-editor-pdf h4 {
        font-weight: 700 !important;
        font-size: 12px !important;
      }

      .wysiwyg-editor-pdf h5, .wysiwyg-editor-pdf h6 {
        font-weight: 700 !important;
        font-size: 11px !important;
      }

      .wysiwyg-editor-pdf p + ul, .wysiwyg-editor-pdf p + ol {
        margin-top: 10px !important;
      }

      .wysiwyg-editor-pdf h1, .wysiwyg-editor-pdf h2, .wysiwyg-editor-pdf h3, .wysiwyg-editor-pdf h4, .wysiwyg-editor-pdf h5, .wysiwyg-editor-pdf h6, .wysiwyg-editor-pdf div[data-type="custom-title-node"] {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }

      .wysiwyg-editor-pdf ul h1, .wysiwyg-editor-pdf ul h2, .wysiwyg-editor-pdf ul h3, .wysiwyg-editor-pdf ul h4, .wysiwyg-editor-pdf ul h5, .wysiwyg-editor-pdf ul h6,
      .wysiwyg-editor-pdf ol h1, .wysiwyg-editor-pdf ol h2, .wysiwyg-editor-pdf ol h3, .wysiwyg-editor-pdf ol h4, .wysiwyg-editor-pdf ol h5, .wysiwyg-editor-pdf ol h6 {
        margin-bottom: 7px;
        margin-top: 7px;
      }

      .wysiwyg-editor-pdf p {
        margin-bottom: 10px !important;
      }

      .wysiwyg-editor-pdf ul p, .wysiwyg-editor-pdf ol p {
        margin: 0 !important;
      }

      .wysiwyg-editor-pdf ul {
        list-style-type: none;
      }

      .wysiwyg-editor-pdf > ul, .wysiwyg-editor-pdf > ol {
        margin-bottom: 20px;
      }

      .wysiwyg-editor-pdf ul > li {
        vertical-align: top;
        position: relative;
        margin-bottom: 0;
        display: table;
        width: 100%;
      }

      .wysiwyg-editor-pdf ul > li:before {
        content: "\\2022";
        position: absolute;
        padding-right: 15px;
        display: table-cell;
        vertical-align: top;
        min-width: 25px;
        height: 100%;
        width: 1px;
        left: -8px;
        top: 1px;
      }

      .wysiwyg-editor-pdf ol {
        list-style-type: none;
        counter-reset: item;
      }

      .wysiwyg-editor-pdf ol > li {
        display: table;
        counter-increment: item;
        width: 100%;
      }

      .wysiwyg-editor-pdf ol > li:before {
        content: counters(item, ".") ". ";
        min-width: 25px !important;
        width: 1px !important;
        padding-right: 15px;
        display: table-cell;
      }

      .wysiwyg-editor-pdf li ol > li:before {
        content: counters(item, ".") ". ";
      }

      .wysiwyg-editor-pdf strong,
      .wysiwyg-editor-pdf b {
        font-weight: 700;
      }

      .wysiwyg-editor-pdf em,
      .wysiwyg-editor-pdf i {
        font-style: italic;
      }

      .wysiwyg-editor-pdf a:not([class*='btn-']) {
        text-decoration: underline;
      }

      .wysiwyg-editor-pdf img {
        width: auto;
        max-width: 220px;
        height: auto;
        object-fit: contain;
        margin: 0 auto;
        display: block;
      }

      .wysiwyg-editor-pdf blockquote {
        margin: 30px auto;
        width: 67%;
      }

      .wysiwyg-editor-pdf div[data-type="custom-page-divider"] {
        page-break-after: always !important;
        display: block !important;
        margin: 20px 0 !important;
        height: 2px;
        width: 100%;
      }

      .wysiwyg-editor-pdf hr {
        margin: 10px 0 !important;
        display: block !important;
      }

      .wysiwyg-editor-pdf ul + h1, .wysiwyg-editor-pdf ul + h2, .wysiwyg-editor-pdf ul + h3, .wysiwyg-editor-pdf ul + h4, .wysiwyg-editor-pdf ul + h5, .wysiwyg-editor-pdf ul + h6,
      .wysiwyg-editor-pdf ol + h1, .wysiwyg-editor-pdf ol + h2, .wysiwyg-editor-pdf ol + h3, .wysiwyg-editor-pdf ol + h4, .wysiwyg-editor-pdf ol + h5, .wysiwyg-editor-pdf ol + h6 {
        margin-top: 30px;
      }
    `;

    document.head.appendChild(style);

    content.innerHTML = content.innerHTML.replace(/(\r\n|\n|\r)/gm, "");

    const ul = content.querySelectorAll('ul');
    const ol = content.querySelectorAll('ol');
    const uli = content.querySelectorAll('ul li');
    const oli = content.querySelectorAll('ol li');
    const tables = content.querySelectorAll('table');
    const customParagraphs = content.querySelectorAll('div[data-type="custom-section-node"]');
    const hyperlinks = content.querySelectorAll('a');

    hyperlinks.forEach((link: any) => {
      const el = document.createElement('span')
      el.textContent = link.textContent
      link.parentNode.replaceChild(el, link)
    })

    this.wrapUncontainedTextWithSpan(uli);
    this.wrapUncontainedTextWithSpan(oli);

    uli.forEach((el: HTMLElement) => {
      el.style.position = 'relative';
      el.style.lineHeight = '1.5';
      el.style.fontSize = '9px';
    })

    oli.forEach((el: HTMLElement) => {
      el.style.position = 'relative';
      el.style.lineHeight = '1.5';
      el.style.fontSize = '9px';
    })

    const paragraphs = content.querySelectorAll('p');
    this.wrapUncontainedTextWithSpan(paragraphs);

    this.wrapUncontainedTextWithSpan(content.querySelectorAll('strong'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('del'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('em'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('b'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('i'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('u'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('s'))

    paragraphs.forEach((p: HTMLParagraphElement) => {
      p.style.lineHeight = '1.5';
      p.style.display = 'block';
      p.style.fontSize = '9px';
    })

    new Set([...ul, ...ol]).forEach((el: HTMLElement) => {
      el.style.position = 'relative';
      el.style.marginBottom = '8px';
      el.style.paddingLeft = '10px';

      el.innerHTML = el.innerHTML.replace(/(<p)/igm, '<span').replace(/<\/p>/igm, '</span>')

      el.querySelectorAll('span').forEach((span: HTMLElement) => {
        span.style.color = '#38303b';
      })
    })

    new Set([...content.querySelectorAll('ul ul'), ...content.querySelectorAll('ul ol'), ...content.querySelectorAll('ol ul'), ...content.querySelectorAll('ol ol')]).forEach((el: HTMLElement) => {
      el.style.paddingLeft = '10px';
      el.style.marginBottom = '8px';
    })

    new Set([...content.querySelectorAll('ul li > ul'), ...content.querySelectorAll('ul li > ol'), ...content.querySelectorAll('ol li > ul'), ...content.querySelectorAll('ol li > ol')]).forEach((el: HTMLElement) => {
      el.style.marginTop = '8px';
    })

    this.wrapUncontainedTextWithSpan(content.querySelectorAll('ul li > span'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('ol li > span'))

    const counters = content.querySelectorAll('.counter');

    counters.forEach((counter: HTMLSpanElement) => {
      counter.style.position = 'absolute';
      counter.style.right = 'calc(100% + 3px)';
      counter.style.top = '1.5px';
      counter.style.textAlign = 'right';
    });

    const headings1 = content.querySelectorAll('h1');
    const headings2 = content.querySelectorAll('h2');
    const headings3 = content.querySelectorAll('h3');
    const headings4 = content.querySelectorAll('h4');
    const headings5 = content.querySelectorAll('h5');
    const headings6 = content.querySelectorAll('h6');

    new Set([...headings1, ...headings2]).forEach((h1: HTMLSpanElement) => {
      h1.style.fontSize = '13px';
      h1.style.fontWeight = '700';
      h1.style.display = 'block';
      h1.style.lineHeight = '1.5';
    })

    headings2.forEach((h2: HTMLSpanElement) => {
      h2.style.fontSize = '12px';
    })

    new Set([...headings3, ...headings4, ...headings5, ...headings6]).forEach((h3: HTMLSpanElement) => {
      h3.style.fontSize = '11px';
      h3.style.fontWeight = '700';
      h3.style.display = 'block';
      h3.style.lineHeight = '1.5';
    })

    tables.forEach((table: HTMLTableElement) => {
      table.style.width = '100%';
      table.style.tableLayout = 'fixed';
      table.style.marginTop = '20px';
      table.style.marginBottom = '10px';
    })

    let i = 0;
    customParagraphs.forEach((p: HTMLDivElement) => {
      i++;
      p.innerHTML = `&sect;${i}<br> ${p.textContent}`;
      p.style.textAlign = 'center';
      p.style.fontSize = '13px';
      p.style.fontWeight = '700';
      p.style.marginTop = '25px';
      p.style.marginBottom = '25px';
    });

    let file: any = null;

    const pageWidth = doc.internal.pageSize.getWidth() - 60;

    content.querySelectorAll('span.mention').forEach((node: any) => {
      this.unwrap(node)
    })

    await doc.html(content, {
      margin: [30, 30, 60, 30],
      autoPaging: 'text',
      windowWidth: parseInt(pageWidth.toString()),
      width: parseInt(pageWidth.toString()),
      fontFaces: [
        {
          family: 'Poppins',
          style: 'normal',
          weight: '400',
          stretch: 'normal',
          src: [{
            url: require('../../../../../../../../assets/Poppins-Regular.ttf'), format: 'truetype' }],
        },
        {
          family: 'Poppins',
          style: 'italic',
          weight: '400',
          stretch: 'normal',
          src: [{
            url: require('../../../../../../../../assets/Poppins-Italic.ttf'), format: 'truetype' }],
        },
        {
          family: 'Poppins',
          style: 'normal',
          weight: '700',
          stretch: 'normal',
          src: [{
            url: require('../../../../../../../../assets/Poppins-Bold.ttf'), format: 'truetype' }],
        },
        {
          family: 'Poppins',
          style: 'italic',
          weight: '700',
          stretch: 'normal',
          src: [{
            url: require('../../../../../../../../assets/Poppins-BoldItalic.ttf'), format: 'truetype' }],
        },
      ],
      callback: (doc) => {
        const pageCount = (doc.internal as any).getNumberOfPages();

        // For each page, print the page number and the total pages
        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);

          //Print Page 1 of 4 for example
          doc.setFontSize(10);
          doc.text(String(i) + ' / ' + String(pageCount), ((doc as any).getPageWidth()) / 2, ((doc as any).getPageHeight()) - 30, {
            align: 'center',
            flags: {
              noBOM: false,
              autoencode: true
            }
          });
        }
        
        file = new File([doc.output('blob')], `${fileName}.pdf`, { type: 'application/pdf' });
      }
    });
    
    document.head.removeChild(style);
    
    return file;
  }

  async uploadFiles(files: File[]) {
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);
    try {
      const promises: Array<Promise<any>> = [];
      files.forEach(async (file: File) => {
        promises.push(this.$store.dispatch('genprox/uploadFile', { file, config }));
      })

      const uploadedFiles = await Promise.all(promises);

      return uploadedFiles;
    } catch (e) {
      e;
    }
    this.uploadProgress.progress = -1;
  }

  async getPaymentsList() {
    this.isLoading = true
    try {
      const payments: any[] = await this.$store.dispatch('investors/getProductPayments', this.productId)
      this.paymentsList = payments
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.isLoading = false
  }

  async refreshPaymentConfirmations() {
    await this.loadPaymentConfirmations();
    (this.$refs.paymentConfirmations1 as PaymentConfirmations).isLoading = false;
    (this.$refs.paymentConfirmations2 as PaymentConfirmations).isLoading = false;
  }

  async loadPaymentConfirmations() {
    this.paymentConfirmations = await this.$store.dispatch('investors/getPaymentConfirmations', this.productId)
  }

  async loadProductData() {
    if (this.action === ProductSummaryAction.add) {
      this.product = this.$store.getters['investors/newProduct'];
    }

    if (this.productId) {
      this.loadPaymentConfirmations()
    }

    if (this.product?.subscriptionId && this.isEquity) {
      this.loadSubscriptionSlots()
    }

    switch (this.action) {
      case ProductSummaryAction.add: {
        const issuer = this.activeUserData.context;
        const issuerData = await this.$store.dispatch('genprox/getLeContextData', issuer.id);
        this.issuer = issuerData
        
        if (this.product?.investmentClientId) {
          const investorData: Address & { name: string } = await
            this.$store.dispatch('investors/getInvestmentClientAddress', this.product.investmentClientId)
          this.investor = {
            name: investorData.name, address: {
              zipCode: investorData.zipCode,
              houseNumber: investorData.houseNumber,
              street: investorData.street,
              city: investorData.city,
              country: investorData.country,
              flatNumber: investorData.flatNumber
            }
          }
        }

        if (this.product?.investorLegalEntityId) {
          const investorData: any = await this.$store.dispatch('genprox/getLeContextData', this.product.investorLegalEntityId)
          this.investor = { name: investorData.name, address: investorData.address }
        }

        break;
      }
      case ProductSummaryAction.summaryByInvestmentClient: {
        const payload: GetProduct = {
          id: this.productId,
          type: 'for-investment-client'
        };
        const { data: fullProduct } = await store.dispatch('investors/getProduct', payload);
        this.product = fullProduct;
        const issuerData = await this.$store.dispatch('genprox/getLeContextData', fullProduct?.issuer.id);
        this.issuer = issuerData;
        this.investor = fullProduct.investor;

        if (this.product?.investmentValue && this.product?.campaignId) {
          this.actualProductData = {
            campaignId: this.product.campaignId,
            investmentValue: this.product.investmentValue,
          }
        }

        break;
      }
      case ProductSummaryAction.summaryByLegalEntity: {
        const payload: GetProduct = {
          id: this.productId,
          type: 'for-legal-entity'
        };
        const { data: fullProduct } = await store.dispatch('investors/getProduct', payload);
        this.product = _.cloneDeep(fullProduct);
        const issuerData = await this.$store.dispatch('genprox/getLeContextData', fullProduct?.issuer.id);
        this.issuer = issuerData;
        this.investor = fullProduct.investor;

        await this.$store.dispatch('investors/getProductStatuses', this.productId)

        if (this.product?.investmentValue && this.product?.campaignId) {
          this.actualProductData = {
            campaignId: this.product.campaignId,
            investmentValue: this.product.investmentValue,
          }
        }

        break;
      }
      case ProductSummaryAction.acceptByIdByLegalEntity: {
        if (this.activeUserData.role.marketplace === 'broker') {
          await this.$router.replace({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise/product/summary/${this.productId}/for-legal-entity`});
        }
        const payload: GetProduct = {
          id: this.productId,
          type: 'for-legal-entity'
        };
        const { data: fullProduct } = await store.dispatch('investors/getProduct', payload);
        this.product = fullProduct;
        const issuerData = await this.$store.dispatch('genprox/getLeContextData', fullProduct?.issuer.id);
        this.issuer = issuerData;
        this.investor = fullProduct.investor;
        break;
      }
      case ProductSummaryAction.acceptByIdByInvestmentClient: {
        const response: AxiosResponse<ProductSummaryData> = await this.$store.dispatch('investors/getProduct', { type: "for-investment-client", id: this.productId });
        this.product = response.data;
        const issuerData = await this.$store.dispatch('genprox/getLeContextData', response?.data?.issuer.id);
        this.issuer = issuerData;
        this.investor = response.data.investor;
        break;
      }
    }

    if(this.product[this.productDataName].initialPaymentBankAccount === null) {
      this.product[this.productDataName].initialPaymentBankAccount = {
        account: '',
        owner: null,
        country: 'PL',
        swift: null,
      }
    }

    if(this.product[this.productDataName].principalPaymentBankAccount === null) {
      this.product[this.productDataName].principalPaymentBankAccount = {
        account: '',
        owner: null,
        country: 'PL',
        swift: null,
      }
    }

    if(this.product[this.productDataName].interestPaymentBankAccount === null) {
      this.product[this.productDataName].interestPaymentBankAccount = {
        account: '',
        owner: null,
        country: 'PL',
        swift: null,
      }
    }

    if(this.product?.campaignProductOfferId) {
      this.assignCampaign = true;
      this.assignedCampaign = this.product.campaignId;
    } else {
      this.assignCampaign = this.product?.campaignId ? true : false;
      this.assignedCampaign = this.product?.campaignId || null;
    }

    if (this.product?.annexSourceReferenceIds?.length) {
      await this.loadAnnexData();
    }

    if(this.product?.clatTax && this.productId && !this.isEquity) {

      // check if has clat tax
      try {
        const response = await this.$store.dispatch('products/getClatTax', this.productId);
        this.hasClatTaxGenerated = true;

        this.clatTaxData = {
          description: response?.description,
          baseValue: response?.baseValue,
          convert: response?.convert,
          convertedValue: response?.convertedValue,
          currency: response?.currency,
          paymentDate: response?.paymentDate,
          paymentDeadline: response?.paymentDeadline,
          paymentStatus: response?.paymentStatus,
          rate: response?.rate,
          rateDate: response?.rateDate,
          value: response?.value,
        }

      } catch(e: any) {
        this.hasClatTaxGenerated = false;
        if(e?.response?.status != 404) {
          const errorMessage = this.$options.filters.errorHandler(e);
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: this.$t(errorMessage).toString()
          });
        } else {
          this.setClatTaxDefaultValues();
        }
      }
    }

    if (this.product?.rate) {
      this.$set(this.productExchangeInfo, 'rate', this.product?.rate)
    }
    
    if (this.product?.rateDate) {
      this.$set(this.productExchangeInfo, 'rateDate', this.product?.rateDate)
    }

    if (this.product[this.productDataName]?.simpleOptions?.interest === 0) {
      this.product[this.productDataName].simpleOptions.interest = null
    }

    if(this.product.paymentValue && this.product.paymentDate && (this.isMarketplaceAdmin || this.isMarketplaceHead || this.isMarketplaceAccountant) && !this.isEquity) {
      // await this.generateSchedule(false);
      if(this.hasGeneratedSchedule) {
        await this.generateSchedule(false);
      } else {
        await this.getSchedule();
      }
    }
  }

  setClatTaxDefaultValues() {
    if (this.annexedProducts?.length) {
      let total: number = 0;

      this.annexedProducts.forEach((item: any) => {
        total = math.number(math.add(math.bignumber(total), math.bignumber(item.investmentValue ? item.investmentValue : 0)))
      })

      const diff: number = math.number(math.subtract(math.bignumber(this.product.investmentValue), math.bignumber(total)))

      this.clatTaxData.baseValue = diff <= 0 ? 0 : diff
    } else {
      this.clatTaxData.baseValue = math.number(math.round(math.bignumber(this.product.investmentValue), 2));
    }

    this.clatTaxData.currency = this.product.currency;
    this.clatTaxData.convert = !(this.product.currency === 'PLN');
    this.clatTaxData.convertedValue = this.calculateClatTaxConvertedValue();
    this.clatTaxData.value = this.calculateClatTaxValue();
    if (this.product?.agreementDate) {
      this.clatTaxData.paymentDeadline = moment(new Date(this.product.agreementDate)).add(14, 'days').format('YYYY-MM-DD');
    }
  }

  async saveClatTax() {
    if (!this.canSaveClatTax) {
      return
    }
    this.$v.$reset();
    this.$v.$touch();

    if(!this.$v.$error) {
      try {
        this.isClatTaxLoading = true;
        await this.$store.dispatch('products/saveClatTax', { productId: this.productId, data: this.clatTaxData });
        this.hasClatTaxGenerated = true;
        this.$notify({
          type: 'success',
          title: 'Success',
          text: 'CLAT tax saved.'
        })
      } catch(error) {
        const errorMessage = this.$options.filters.errorHandler(error);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        });
      }
      this.isClatTaxLoading = false;
    } else {
      this.$nextTick(() => {
        const appContentDiv: HTMLDivElement = document.querySelector('.app-content');
        if (appContentDiv.querySelectorAll('.error')[0]) {
          (appContentDiv.querySelectorAll('.error')[0] as HTMLDivElement).scrollIntoView({
            behavior: 'smooth'
          });
        }
      })
    }
  }

  async deleteClatTax() {
    try {
      this.isClatTaxLoading = true;
      await this.$store.dispatch('products/deleteClatTax', this.productId);
      this.setClatTaxDefaultValues();
      this.hasClatTaxGenerated = false;
      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'CLAT tax cleared.'
      })
    } catch(error) {
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
    this.isClatTaxLoading = false;
    this.closeClearClatTaxConfirmationModal();
  }

  calculateClatTaxConvertedValue() {
    return math.round(math.multiply(math.number(math.bignumber(this.clatTaxData.rate)), math.number(math.bignumber(this.clatTaxData.baseValue))), 2)
  }

  calculateClatTaxValue() {
    return this.clatTaxData.currency === 'PLN' ? math.round(math.multiply(math.number(math.bignumber(this.clatTaxData.baseValue)), 0.005), 2) : math.round(math.multiply(math.number(math.bignumber(this.clatTaxData.convertedValue)), 0.005), 2);
  }

  async loadAnnexData() {
    try {
      const promises: any[] = [];

      this.product.annexSourceReferenceIds.forEach((id: string) => {
        promises.push(this.$store.dispatch('investors/getProduct', { id, type: 'for-legal-entity' }))
        promises.push(this.$store.dispatch('products/getRepaymentSchedule', id))
      })

      const response: any = await Promise.all(promises);
      const annexedProducts: any = [];

      for (let index = 0; index <= response.length / 2; index = index + 2) {
        annexedProducts.push({ show: index === 0 ? true : false, id: response[index]?.data?.id, code: response[index]?.data?.code, schedule: response[index + 1], investmentValue: response[index]?.data?.investmentValue })
      }

      this.annexedProducts = annexedProducts;

      this.$nextTick(() => {
        this.$store.commit('investors/setNewProductAnnexData', { type: this.product.annexType, ids: this.product.annexSourceReferenceIds })
        this.$store.commit('investors/setAnnexingProducts', this.annexedProducts)
      })

    } catch(error) {
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
  }

  async loadCampaigns() {
    try {
      const resp = await this.$store.dispatch('campaigns/getAllCampaigns', this.product?.type)
      this.campaigns = resp;
    } catch (error) {
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
  }

  updateSlots(availableSlots: any[], usedRange: number[]) {
    let updatedSlots = [];
    let inserted = false;

    for (let slot of availableSlots) {
      // Check if there is an overlap
      if (slot.counterFrom <= usedRange[1] && slot.counterTo >= usedRange[0]) {
        // Merge or extend the slot to cover the used range
        slot.counterFrom = Math.min(slot.counterFrom, usedRange[0]);
        slot.counterTo = Math.max(slot.counterTo, usedRange[1]);
        inserted = true;
      }
      updatedSlots.push(slot);
    }

    // If no overlap, insert a new slot for the used range
    if (!inserted) {
      updatedSlots.push({ counterFrom: usedRange[0], counterTo: usedRange[1] });
    }

    // Sort the updated slots by counterFrom
    updatedSlots.sort((a, b) => a.counterFrom - b.counterFrom);

    // Merge overlapping slots
    let mergedSlots = [];
    let currentSlot = updatedSlots[0];
    for (let i = 1; i < updatedSlots.length; i++) {
      let nextSlot = updatedSlots[i];
      if (currentSlot.counterTo >= nextSlot.counterFrom) {
        currentSlot.counterTo = Math.max(currentSlot.counterTo, nextSlot.counterTo);
      } else {
        mergedSlots.push(currentSlot);
        currentSlot = nextSlot;
      }
    }
    mergedSlots.push(currentSlot);

    // Merge adjacent slots
    let finalSlots = [];
    let prevSlot = mergedSlots[0];
    for (let i = 1; i < mergedSlots.length; i++) {
      let nextSlot = mergedSlots[i];
      if (prevSlot.counterTo + 1 === nextSlot.counterFrom) {
        prevSlot.counterTo = nextSlot.counterTo;
      } else {
        finalSlots.push(prevSlot);
        prevSlot = nextSlot;
      }
    }
    finalSlots.push(prevSlot);

    finalSlots = finalSlots?.map((el: any) => {
      el.instrumentCount = math.number(math.bignumber(math.subtract(math.bignumber(el?.counterTo || 0), math.bignumber(el?.counterFrom - 1 || 0))))

      return el
    })

    return finalSlots;
  }

  async loadSubscriptionSlots() {
    this.subscriptionSlots = await this.$store.dispatch('subscriptions/getSlots', this.product?.subscriptionId)

    this.subscriptionSlots = this.subscriptionSlots?.map((el: any) => {
      if (el?.seriesOfSharesName === this.product?.data?.seriesOfShares?.name) {
        // el.slots = this.updateSlots(el?.slots, [this.product?.data?.seriesOfShares?.counterFrom, this.product?.data?.seriesOfShares?.counterTo])
        el.subscriptionPool = math.number(math.bignumber(math.add(math.bignumber(el.subscriptionPool), math.bignumber(this.product?.data?.seriesOfShares?.instrumentCount || 0))))
      }

      return el
    })
  }

  async loadSubscriptionData() {
    try {
      const subscription = await this.$store.dispatch('subscriptions/getSubscription', this.product?.subscriptionId)
      this.seriesOfShares = subscription?.seriesOfShares
      this.subscription = subscription

      if (subscription?.legalEntityId) {
        this.legalEntityData = await this.$store.dispatch('genprox/getLeContextData', subscription?.legalEntityId)
      }

      await this.loadSubscriptionSlots()

      if (this.product.type === 'JOINT_STOCK_SHARES' && this.product.data?.seriesOfShares?.name) {
        const serieItems = this.seriesOfShares.filter((el: any) => el.name === this.product?.data?.seriesOfShares?.name)

        let selectedSeries = this.seriesOfShares.find((el: any) => el.name === this.product?.data?.seriesOfShares?.name)

        if (this.product?.data?.seriesOfShares?.counterFrom) {
          const counterFrom = this.product?.data?.seriesOfShares?.counterFrom
          const foundSeries = serieItems?.find((el: any) => counterFrom >= el.counterFrom && counterFrom <= el.counterTo)

          if (foundSeries) {
            selectedSeries = foundSeries
          }
        }

        if (selectedSeries && !this.product?.data?.seriesOfShares?.issueValue) {
          this.product.data.seriesOfShares.nominalValue = selectedSeries?.nominalValue
          this.product.data.seriesOfShares.issueValue = selectedSeries?.issueValue
        }
      }

      if (this.product.type === 'LLC_SHARES') {
        this.product.data.llcShares.nominalValue = subscription?.llcShares?.nominalValue
        this.product.data.llcShares.issueValue = this.product?.data?.llcShares?.issueValue
      }
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
      this.$router.push(`/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise?page=subscriptions`);
    }
  }

  async getLegalEntityData() {
    const resp = await this.$store.dispatch('genprox/getContextData')
    this.contextData = resp
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  async beforeMount() {
    this.isLoading = true;

    this.$store.dispatch('products/getDictionaries');

    if (this.activeUserData.context.context !== Contexts.privateInvestor &&
      this.activeUserData.context.context !== Contexts.fundManagerPrivate) {
      try {
        const walletAddress = await this.$store.dispatch('genprox/getContextMarketplaceWalletNumber');
        this.$store.commit('genprox/setActiveContextMarketplaceWallet', walletAddress);
      } catch (err) {
        console.log(err);
      }
    }
    
    await this.loadProductData();

    const promises: Promise<any>[] = [this.loadTemplate(), this.getLegalEntityData()]

    if (!this.isMarketplaceAccountant) {
      promises.push(this.loadCampaigns())
    }

    if (this.isEquity) {
      await this.loadSubscriptionData()
    }

    if (this.manageProductsEnabled) {
      // keep always as first item of array
      promises.unshift(this.$store.dispatch('templates/getTemplateChoices', { type: 'template', context: this.activeUserData?.context?.id }));
    }

    const resp = await Promise.all(promises)
    
    this.templatesList = resp[0]

    // if (this.isEquity) {
    //   await this.loadSubscriptionData();
    // }

    this.fixedReplacementDay = this.product[this.productDataName]?.timeType === TimeTypes.fixedRepaymentDate ? true : false;

    if(this.product?.investmentClientId) {
      if(this.action === ProductSummaryAction.acceptByIdByInvestmentClient || this.action === ProductSummaryAction.summaryByInvestmentClient) {
        await store.dispatch('profile/getProfileData');
        this.investorDetails = this.$store.getters['profile/getProfileData'];
      } else {
        this.investorDetails = await store.dispatch('investors/getInvestorProfile', this.product.investmentClientId);
      }

      if (this.investorDetails?.clientType === 'legal-entity') {
        const legalEntityInvestorData = await this.$store.dispatch('genprox/getLeContextData', this.investorDetails?.clientId);
        this.legalEntityInvestorData = legalEntityInvestorData;
      }
    }

    // if(this.product?.paymentDate) {
    //   this.paymentData.paymentDate = this.product.paymentDate;
    // }

    // if(this.product?.paymentValue) {
    //   this.paymentData.paymentValue = this.product.paymentValue;
    // }

    this.isLoading = false;
  }

  getCustomAttachments() {
    const attachmentsToPost: TemplateAttachment[] = (this.$refs.attachments as Attachments).attachmentsToPost;

    if(attachmentsToPost.length) {
      return attachmentsToPost.map((el: TemplateAttachment) => {
        return el.referenceId;
      });
    }

    return [];
  }

  formatFileName(name: string) {
    let extension: string = null

    if (Utils.getFileExtensions(name)) {
      extension = Utils.getFileExtensions(name)
    }

    const nameArray: string[] = name?.split(`.${extension}`)

    if (nameArray?.length > 1) {
      return `${nameArray[0].replaceAll('.', '')}.${extension}`
    }

    return name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "")
  }

  async downloadGeneratedAgreement() {
    const agreement = this.agreementTemplate
    const file = await this.generatePdf(agreement.content, agreement.title);
    const fileUrl = Utils.createFileUrlFromContent(file, 'application/pdf')
    Utils.downloadFileByUrl(fileUrl, this.formatFileName(file?.name))
  }

  async createAgreement(returnFiles: boolean = false) {
    let attachments: AttachmentObject[] = this.product.template.attachments.filter((el: TemplateAttachment) => el.type === 'FILE').map((el: TemplateAttachment) => {
      if(returnFiles) {
        return {
          fileName: el.name,
          filePath: el.path,
          id: el.id,
          ownerId: el.referenceId
        }
      }

      return {
        fileId: el.referenceId,
        fileName: el.name,
      }
    });

    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);

    const attachmentTemplates = this.product.template.attachments.filter((el: TemplateAttachment) => el.type === 'TEMPLATE');

    for (const attachment of attachmentTemplates) {
      const agreement = await this.$store.dispatch('templates/getAgreementTemplate', attachment.referenceId);
      const file = await this.generatePdf(agreement.content, attachment.name);
      const agreementFile = await this.$store.dispatch('genprox/uploadFile', { file, config })

      if(returnFiles) {
        attachments.push(agreementFile);
      } else {
        attachments.push({
          fileId: agreementFile.id,
          fileName: agreementFile.fileName,
        });
      }

    }

    const agreement: AgreementObject | { agreement: File, attachments: any[] } = await this.generateAgreementFile(attachments, returnFiles);

    return agreement;
  }

  async goNext(): Promise<void> {
    this.isLoading = true;
    try{
      switch (this.action) {
        case ProductSummaryAction.acceptByIdByInvestmentClient: {
          
          // createAgreement function content here earlier
          const agreement = await this.createAgreement(false);
          
          const payload: any = {
            productId: this.product.id,
            data: agreement
          }

          await this.$store.dispatch('investors/acceptProductByClient', payload);
          this.$notify({
            title: 'Product has been approved!',
            type: 'success'
          })
          await this.$router.push({name: 'welcome'});

          break;
        }
        case ProductSummaryAction.acceptByIdByLegalEntity: {
          await this.$store.dispatch('investors/acceptProductByLegalEntity', this.product.id);
          this.$notify({
            title: 'Product has been approved!',
            type: 'success'
          })
          await this.$router.push(`/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise`);
          break;
        }
        case ProductSummaryAction.add: {
          this.product.customAttachmentIds = this.getCustomAttachments();

          if(this.product?.code === '') {
            this.product.code = null;
          }

          if(!(this.isMarketplaceAdmin || this.isMarketplaceHead)) {
            this.product.data.initialPaymentBankAccount = null;
            this.product.data.principalPaymentBankAccount = null;
            this.product.data.interestPaymentBankAccount = null;
            this.product.data.debtor = null;
          }

          if(!this.product[this.productDataName]?.debtor?.account && !this.product[this.productDataName]?.debtor?.name && !this.product[this.productDataName]?.debtor?.type) {
            this.product.data.debtor = null;
          }

          if (this.isEquity) {
            delete this.product?.data?.duration
            delete this.product?.data?.advancedOptions
            delete this.product?.data?.simpleOptions
            delete this.product?.data?.debtor
            delete this.product?.data?.timeType
          }

          const id: string = await this.$store.dispatch('investors/addNewProduct', this.product);

          if(this.annexedProducts.length) {
            await this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise/product/success?id=${id}`});
          } else {
            await this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise/product/success`});
          }

          break;
        }
        case ProductSummaryAction.summaryByLegalEntity:
        case ProductSummaryAction.summaryByInvestmentClient: {
          this.$router.back();
          break;
        }
      }
    } catch (e){
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.isLoading = false;
  }

  async confirmRejectOffer() {
    this.rejectOfferLoading = true;
    switch (this.action) {
      case ProductSummaryAction.acceptByIdByLegalEntity:
        await this.$store.dispatch('investors/declineProductByLegalEntity', this.product.id);
        await this.$router.push(`/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise`);
        break;
      case ProductSummaryAction.acceptByIdByInvestmentClient:
        await this.$store.dispatch('investors/rejectProduct', this.product.id);
        await this.$router.back();
        break;
    }
    this.$notify({
      duration: 2500,
      type: 'success',
      title: 'Product has been rejected'
    });
    this.rejectOfferLoading = false;
  }

  rejectOffer() {
    this.showRejectConfirmationModal = true
  }

  async confirmDeclineProduct() {
    this.rejectOfferLoading = true;
    try {
      await this.$store.dispatch('investors/declineProductByLegalEntity', this.product.id);
      await this.$router.push(`/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise`);
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Product has been declined'
      });
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
    this.rejectOfferLoading = false;
  }

  handleDeclineConfirmationModal() {
    if (this.showDeclineConfirmationModal) {
      this.confirmDeclineProduct()
    } else if (this.showRejectConfirmationModal) {
      this.confirmRejectOffer()
    }
  }

  openMarkAsClosedModal() {
    this.showCloseConfirmationModal = true
  }

  handleCloseConfirmationModal() {
    this.markAsClosed()
  }

  declineProduct() {
    this.showDeclineConfirmationModal = true
  }

  async finishProduct() {
    this.finishProductLoading = true;

    try {
      await this.$store.dispatch('investors/finishProduct', this.product.id);
      await this.$router.push(`/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise`);
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Product has been approved'
      });
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }

    this.finishProductLoading = false;
  }

  isAutentiProcessing(autentiStatus: string) {
    return autentiStatus === 'NEW' || autentiStatus === 'DRAFT' || autentiStatus === 'WITHDRAWING'
  }

  async checkAutentiStatuses() {
    this.isAgreementGenerating = true

    try {
      const autentiData = await this.$store.dispatch('investors/getProductStatuses', this.productId)
      const autentiStatus = autentiData?.autentiStatus?.toUpperCase()
      if (!this.isAutentiProcessing(autentiStatus)) {
        this.isAgreementGenerating = false
      }
    } catch (e) {
      e;
    }
  }

  openAutentiCheckModal(type: 'send-product' | 'withdraw-product') {
    this.$set(this.autentiCheckModal, 'type', type)
    this.$set(this.autentiCheckModal, 'show', true)
  }

  closeAutentiCheckModal() {
    this.$set(this.autentiCheckModal, 'show', false)
  }

  confirmAutentiCheckModal() {
    if (this.autentiCheckModal?.type === 'send-product') {
      this.autentiSendProduct()
    } else if (this.autentiCheckModal?.type === 'withdraw-product') {
      this.autentiWithdrawProduct()
    }
  }

  autentiSendProductHandler() {
    if (this.autentiData?.autentiStatus?.toUpperCase() === 'ERROR') {
      this.openAutentiCheckModal('send-product')
    } else {
      this.autentiSendProduct()
    }
  }
  
  autentiWithdrawProductHandler() {
    if (this.autentiData?.autentiStatus?.toUpperCase() === 'ERROR') {
      this.openAutentiCheckModal('withdraw-product')
    } else {
      this.autentiWithdrawProduct()
    }
  }

  async autentiSendProduct() {
    this.closeAutentiCheckModal()
    this.isAgreementGenerating = true
    
    try {
      const fileToSignIds = this.product?.agreementData?.agreements?.map((el: any) => el?.id)
      await this.$store.dispatch('investors/autentiSendProduct', {
        productId: this.productId,
        data: {
          fileToSignIds
        }
      })
      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Send product request has been sent to Autenti.'
      })
      setTimeout(async () => {
        this.isAgreementGenerating = false
        await this.$store.dispatch('investors/getProductStatuses', this.productId)
      }, 1000)
    } catch (e) {
      this.isAgreementGenerating = false
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }

  async autentiWithdrawProduct() {
    this.closeAutentiCheckModal()
    this.isAgreementGenerating = true

    try {
      await this.$store.dispatch('investors/autentiWithdrawProduct', this.productId)
      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Product withdrawal request has been sent to Autenti.'
      })
      setTimeout(async () => {
        this.isAgreementGenerating = false
        await this.$store.dispatch('investors/getProductStatuses', this.productId)
      }, 1000)
    } catch (e) {
      this.isAgreementGenerating = false
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }

  async generateAgreement() {
    this.isAgreementGenerating = true;

    try {
      const agreement: any = await this.createAgreement(true);
      const date = moment().utc().format('YYYY-MM-DDTHH:mm:ss+00:00');


      const agreements: any[] = [
        agreement.agreement,
        ...agreement.attachments,
        ...this.product.template.customAttachments.map((el: any) => ({ fileName: el.name, filePath: el.path, id: el.id, ownerId: '' }))
      ].map((obj: any) => ({ ...obj, date }));

      let data = {};

      const signedAttachmentsPayload = {
        agreementFileId: this.product?.agreement.id ? this.product.agreement.id : null,
        agreementName: this.product?.agreement.name ? this.product.agreement.name : null,
        attachments: this.product?.agreement?.attachments ? this.product.agreement.attachments.map((el: any) => {
          const matching = this.product.agreementData.agreements.find((item: any) => item.id === el.sourceId);

          const foundedLink = agreements.find((item: any) => matching.fileName === item.fileName);

          return {
            fileId: el.id,
            fileName: el.name,
            sourceId: foundedLink ? foundedLink.id : el.sourceId,
          }
        }) : [],
      }

      data = {
        agreements: agreements,
        ...this.product[this.productDataName]
      }

      this.product.agreementData = data;

      const payload = {
        productId: this.productId,
        data: data,
      };

      await this.$store.dispatch('investors/saveAgreementData', payload)
      await this.$store.dispatch('investors/saveAgreement', { productId: this.productId, data: signedAttachmentsPayload });
      this.loadProductData();

      this.$notify({
        title: 'Success',
        text: 'Files generated',
        type: 'success'
      })
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }

    this.isAgreementGenerating = false;
  }

  goToEdit(): void {
    this.$router.push('step-two');
  }

  goBack(){
    this.$router.back();
  }
  // TODO: create state machine for this component

  async checkCampaignTotals() {
    const campaign = await this.$store.dispatch('campaigns/getCampaign', this.assignedCampaign)
    this.campaign = campaign
    this.campaignInvestmentReach = campaign?.investmentReach
    this.campaignInvestmentValue = campaign?.investmentValue
  }

  async runCampaignTotalsCheck() {
    if (this.assignedCampaign) {
      this.isLoading = true
      try {
        await this.checkCampaignTotals()
      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e)
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        })
      }
      this.isLoading = false
    } else {
      this.campaignInvestmentReach = null
      this.campaignInvestmentValue = null
    }
  }

  get autentiData() {
    return this.$store.getters['investors/getAutentiStatusData']
  }

  @Watch('autentiData', { deep: true, immediate: true }) onAutentiDataUpdate() {
    if (this.refresh) {
      clearTimeout(this.refresh);
      this.refresh = undefined;
    }

    const autentiStatus = this.autentiData?.autentiStatus?.toUpperCase()

    if(this.isAutentiProcessing(autentiStatus)) {
      this.isAgreementGenerating = true
      this.refresh = setTimeout(() => {
        this.checkAutentiStatuses();
      }, 3000);
    }
  }

  @Watch('assignedCampaign') async onAssignCampaignChange() {
    await this.runCampaignTotalsCheck()
  }

  @Watch('clatTaxData.baseValue') onClatTaxBaseValueUpdate() {
    this.clatTaxData.convertedValue = this.calculateClatTaxConvertedValue();
    this.clatTaxData.value = this.calculateClatTaxValue();
  }

  @Watch('clatTaxData.currency') onClatTaxCurrencyUpdate() {
    this.clatTaxData.convert = this.clatTaxData.currency !== 'PLN'
  }

  @Watch('clatTaxData.convert') onClatTaxConvertUpdate() {
    if(!this.clatTaxData.convert) {
      this.clatTaxData.rate = 1.0000;
    }
  }

  @Watch('clatTaxData.rate') onClatTaxRateUpdate() {
    this.clatTaxData.rate = Number(Number(String(this.clatTaxData.rate).replaceAll(' ', '').replaceAll(',', '.')).toFixed(4)) || 1;

    this.clatTaxData.convertedValue = this.calculateClatTaxConvertedValue();
  }

  @Watch('clatTaxData.convertedValue') onClatTaxConvertedValueUpdate() {
    this.clatTaxData.value = this.calculateClatTaxValue();
  }

  @Watch('product.data.initialPaymentBankAccount.country') onInitialPaymentBankAccountCountryChange() {
    this.overwriteInitialPaymentBankAccount(false);
  }

  @Watch('product.data.principalPaymentBankAccount.country') onPrincipalPaymentBankAccountCountryChange() {
    this.overwritePrincipalPaymentBankAccount(false);
  }

  @Watch('product.data.interestPaymentBankAccount.country') onInterestPaymentBankAccountCountryChange() {
    this.overwriteInterestPaymentBankAccount(false);
  }

  @Watch('product.loanData.enabledAdvancedOptions') onLoanEnabledAdvancedOptionsChanged() {
    if(this.editable && !this.isLoading) {
      this.removeUnselectedProductData();
      if (this.product[this.productDataName].enabledAdvancedOptions && this.product[this.productDataName]?.advancedOptions === null) {
        this.product[this.productDataName].advancedOptions = {
          commission: null,
          commissionPayment: 'Monthly',
          commissionType: 'percent',
          margin: null,
          marginPayment: 'Monthly',
          marginType: 'percent',
        }
      } else if (!this.product[this.productDataName].enabledAdvancedOptions && this.product[this.productDataName]?.simpleOptions === null) {
        this.product[this.productDataName].simpleOptions = {
          interest: null,
          interestPayment: 'Monthly',
        }
      }
    }
  }

  @Watch('product.data.enabledAdvancedOptions') onDataEnabledAdvancedOptionsChanged() {
    if(this.editable && !this.isLoading) {
      this.removeUnselectedProductData();
      if (this.product[this.productDataName].enabledAdvancedOptions && this.product[this.productDataName]?.advancedOptions === null) {
        this.product[this.productDataName].advancedOptions = {
          commission: null,
          commissionPayment: 'Monthly',
          commissionType: 'percent',
          margin: null,
          marginPayment: 'Monthly',
          marginType: 'percent',
        }
      } else if (!this.product[this.productDataName].enabledAdvancedOptions && this.product[this.productDataName]?.simpleOptions === null) {
        this.product[this.productDataName].simpleOptions = {
          interest: null,
          interestPayment: 'Monthly',
        }
      }
    }
  }
  
  @Watch('product.billAgreementData.enabledAdvancedOptions') onBillEnabledAdvancedOptionsChanged() {
    if(this.editable && !this.isLoading) {
      this.removeUnselectedProductData();
      if (this.product[this.productDataName].enabledAdvancedOptions && this.product[this.productDataName]?.advancedOptions === null) {
        this.product[this.productDataName].advancedOptions = {
          commission: null,
          commissionPayment: 'Monthly',
          commissionType: 'percent',
          margin: null,
          marginPayment: 'Monthly',
          marginType: 'percent',
        }
      } else if (!this.product[this.productDataName].enabledAdvancedOptions && this.product[this.productDataName]?.simpleOptions === null) {
        this.product[this.productDataName].simpleOptions = {
          interest: null,
          interestPayment: 'Monthly',
        }
      }
    }
  }

  @Watch('totalInvestmentValue', { immediate: true }) onTotalInvestmentValueChange() {
    if (this.isEquity) {
      this.product.investmentValue = this.totalInvestmentValue
    }
  }

  @Watch('product.data.seriesOfShares.instrumentCount') onSeriesInstrumentCountChange() {
    const instrumentCount = this.product?.data?.seriesOfShares?.instrumentCount
    const counterFrom = this.product?.data?.seriesOfShares?.counterFrom
    if (counterFrom && instrumentCount) {
      this.product.data.seriesOfShares.counterTo = counterFrom + instrumentCount - 1
    } else {
      this.product.data.seriesOfShares.counterTo = null
    }
  }

  @Watch('product.data.seriesOfShares.counterFrom') onSeriesCounterFromChange() {
    const instrumentCount = this.product?.data?.seriesOfShares?.instrumentCount
    const counterFrom = this.product?.data?.seriesOfShares?.counterFrom
    if (counterFrom && instrumentCount) {
      this.product.data.seriesOfShares.counterTo = counterFrom + instrumentCount - 1
    } else {
      this.product.data.seriesOfShares.counterTo = null
    }
  }

  @Watch('product.data.seriesOfShares.name') onSeriesOfSharesNameChange() {
    if (this.seriesOfShares?.length) {
      const serieItems = this.seriesOfShares.filter((el: any) => el.name === this.product?.data?.seriesOfShares?.name)
  
      let series = this.seriesOfShares.find((el: any) => el.name === this.product?.data?.seriesOfShares?.name)
  
      if (this.product?.data?.seriesOfShares?.counterFrom) {
        const counterFrom = this.product?.data?.seriesOfShares?.counterFrom
        const foundSeries = serieItems?.find((el: any) => counterFrom >= el.counterFrom && counterFrom <= el.counterTo)
  
        if (foundSeries) {
          series = foundSeries
        }
      }
  
      if (series) {
        this.product.data.seriesOfShares.counterFrom = this.currentAvailableSubscriptionSlots[0]?.counterFrom || null
        this.product.data.seriesOfShares.instrumentCount = null
        this.product.data.seriesOfShares.nominalValue = series?.nominalValue
        this.product.data.seriesOfShares.issueValue = series?.issueValue
      } else {
        this.product.data.seriesOfShares.nominalValue = null
        this.product.data.seriesOfShares.issueValue = null
      }
    }
  }

  get clatTaxValidation() {
    return {
      clatTaxData: {
        baseValue: { required },
        currency: { required },
        rate: { required },
        rateDate: this.disableClatTaxRate ? {} : { required },
        convertedValue: { required },
        value: { required },
        paymentDeadline: { required },
        paymentStatus: { required },
        paymentDate: {},
        description: {},
      }
    }
  }

  get paymentValidation() {
    return {
      paymentData: {
        paymentValue: { required, minValue: minValue(0.01), maxValue: maxValue(this.maxPaymentValue) },
        paymentDate: { required },
      }
    }
  }

  validations() {
    if(this.openedTab === 'other') {
      return this.clatTaxValidation;
    } else if (this.openedTab === 'schedule' || this.openedTab === 'payment') {
      return this.paymentValidation;
    } else {
      const hasCampaign: boolean = !!(this.product?.campaignProductOfferId)

      const agreementDate: any = { required }

      if (hasCampaign && this.product?.campaign?.startDate) {
        agreementDate['minValue'] = (value: any) => {
          return new Date(value) >= new Date(this.product?.campaign?.startDate);
        } 
      }

      if (hasCampaign && this.product?.campaign?.endDate) {
        agreementDate['maxValue'] = (value: any) => {
          return new Date(value) <= new Date(this.product?.campaign?.endDate);
        }
      }

      const investmentValue: any = { required, minValue: minValue(1) }

      if (this.product?.campaign?.investmentPaymentValue?.min) {
        investmentValue['minValue'] = minValue(this.product?.campaign?.investmentPaymentValue?.min)
      }

      if (this.product?.campaign?.investmentPaymentValue?.max) {
        investmentValue['maxValue'] = maxValue(this.product?.campaign?.investmentPaymentValue?.max)
      }

      let validation: any = {
        product: {
          agreementDate,
          investmentClientId: { required },
          // code: { required },
          // name: { required },
          investmentValue,
          type: required,
          agreementTemplateId: {},
        }
      }

      if (!this.isEquity) {
        validation.product.agreementTemplateId = { required }
        validation.product[this.productDataName] = {
          timeType: { required },
          interestType: { required },
          interestPaidWithin: { required, integer },
          interestCalculationMethod: { required },
          // debtor: { required }
        };

        if (this.product[this.productDataName]?.initialPaymentBankAccount?.account) {
          if (this.ibanSupportedCountries.includes(this.product[this.productDataName]?.initialPaymentBankAccount?.country)) {
            validation.product[this.productDataName].initialPaymentBankAccount = {
              account: { iban: iban(this.product[this.productDataName].initialPaymentBankAccount.country) },
              name: {},
            };
          } else {
            validation.product[this.productDataName].initialPaymentBankAccount = {
              account: { required },
              name: {},
            };
          }

          if (!this.product[this.productDataName]?.initialPaymentBankAccount?.account?.toLowerCase().slice(0, 2).includes('pl')) {
            validation.product[this.productDataName].initialPaymentBankAccount.swift = { required };
          }
        }

        if (this.product[this.productDataName]?.principalPaymentBankAccount?.account) {
          if (this.ibanSupportedCountries.includes(this.product[this.productDataName]?.principalPaymentBankAccount?.country)) {
            validation.product[this.productDataName].principalPaymentBankAccount = {
              account: { iban: iban(this.product[this.productDataName].principalPaymentBankAccount.country) },
              name: {},
            };
          } else {
            validation.product[this.productDataName].principalPaymentBankAccount = {
              account: { required },
              name: {},
            };
          }

          if (!this.product[this.productDataName]?.principalPaymentBankAccount?.account?.toLowerCase().slice(0, 2).includes('pl')) {
            validation.product[this.productDataName].principalPaymentBankAccount.swift = { required };
          }
        }

        if (this.product[this.productDataName]?.interestPaymentBankAccount?.account) {
          if (this.product[this.productDataName]?.interestPaymentBankAccount?.account && this.ibanSupportedCountries.includes(this.product[this.productDataName]?.interestPaymentBankAccount?.country)) {
            validation.product[this.productDataName].interestPaymentBankAccount = {
              account: { iban: iban(this.product[this.productDataName].interestPaymentBankAccount.country) },
              name: {},
            };
          } else {
            validation.product[this.productDataName].interestPaymentBankAccount = {
              account: { required },
              name: {},
            };
          }

          if (!this.product[this.productDataName]?.interestPaymentBankAccount?.account?.toLowerCase().slice(0, 2).includes('pl')) {
            validation.product[this.productDataName].interestPaymentBankAccount.swift = { required };
          }
        }

        if (this.fixedReplacementDay) {
          validation.product[this.productDataName].fixedRepaymentDate = { required };
          if (this.product?.agreementDate) {
            validation.product[this.productDataName].fixedRepaymentDate.minValue = (value: any) => {
              return new Date(value) > new Date(this.product?.agreementDate);
            }
          }
        } else {
          validation.product[this.productDataName].duration = {
            type: { required },
            count: { required, integer, minValue: minValue(1) }
          }
        }
        if (this.product[this.productDataName]?.enabledAdvancedOptions) {
          validation.product[this.productDataName].advancedOptions = {
            commission: { required, greaterThan: greaterThan(0) },
            margin: { required, greaterThan: greaterThan(0) },
            marginPayment: { required },
            commissionType: { required },
            commissionPayment: { required },
            marginType: { required },
          }
        } else {
          validation.product[this.productDataName].simpleOptions = {
            interest: { required, greaterThan: greaterThan(0) },
            interestPayment: { required },
          }
        }
      } else {
        if (this.product.type === 'JOINT_STOCK_SHARES') {
          const inRangeValidators: any[] = this.currentAvailableSubscriptionSlots?.map((el: any) => {
            return between(el?.counterFrom, el?.counterTo)
          })

          validation.product.data = {
            seriesOfShares: {
              name: { required },
              instrumentCount: { required, minValue: minValue(1) },
              counterFrom: { required, inRange: or(...inRangeValidators) },
              counterTo: { required },
              nominalValue: { required },
              issueValue: { required },
            }
          }

          const counterFrom = this.product?.data?.seriesOfShares?.counterFrom
          if (counterFrom) {
            const activeSlotRange: any = this.currentAvailableSubscriptionSlots?.find((el: any) => counterFrom >= el?.counterFrom && counterFrom <= el?.counterTo)

            if (activeSlotRange) {
              validation.product.data.seriesOfShares.instrumentCount.maxValue = maxValue(activeSlotRange?.instrumentCount)
            }
          }

        } else if (this.product.type === 'LLC_SHARES') {
          validation.product.data = {
            llcShares: {
              instrumentCount: { required },
              nominalValue: { required },
              issueValue: { required },
            }
          }
        }
      }

      return validation;
    }
  }
}
